import React from 'react';
import { Col, Row, Typography } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import Nagappa from '../static/assets/rect_nagappa.png';
import Suma from '../static/assets/rect_suma.png';
import Group from '../static/assets/group.png';
import Sadananda from '../static/assets/rect_sadananda.png';
import BlueLine from '../static/assets/blue_line.png';
import AnimatedComponent from './animationContents/animatedComponents';
import AnimatedKeyComponents from './animationContents/animatedKeyComponents';

const { Paragraph, Title } = Typography;

const Founders = () => {
    const { t } = useTranslation('common');

    return (
        <React.Fragment>
            <Row id="founder">
                <Row className="founderWrapper">
                    <AnimatedKeyComponents>
                        <Row className="blue-box">
                            <img src={Group} />
                        </Row>
                    </AnimatedKeyComponents>
                    <Title className="founderTitle">
                        <span className="first_word">Our</span> Founders
                    </Title>
                    <img src={BlueLine} alt="blue_line" />
                    <Row className="Founders_sec">
                        <AnimatedComponent>
                            <Row justify={'space-between'} className="FoundersRow">
                                <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                                    <img src={Sadananda} alt="Sadananda" />
                                </Col>
                                <Col
                                    className="FounderPara"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    xl={16}
                                    xxl={16}
                                >
                                    <Title className="founderName" level={5}>
                                        {t('our_team.founders.name.1')}
                                    </Title>
                                    <p>
                                        {t('our_team.founders.designation.1')}{' '}
                                        {t('our_team.founders.subDesignation.1')}
                                    </p>
                                    <Paragraph className="para">
                                        <Trans
                                            i18nKey="our_team.founders.description.1"
                                            components={{ italic: <i />, bold: <strong /> }}
                                        />
                                    </Paragraph>
                                </Col>
                            </Row>
                        </AnimatedComponent>
                        <AnimatedComponent>
                            <Row justify={'center'} className="FoundersRow">
                                <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                                    <img src={Nagappa} alt="Nagappa" />
                                </Col>
                                <Col
                                    className="FounderPara"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    xl={17}
                                    xxl={16}
                                >
                                    <Title className="founderName" level={5}>
                                        {t('our_team.founders.name.2')}
                                    </Title>
                                    <p>
                                        {t('our_team.founders.designation.2')}{' '}
                                        {t('our_team.founders.subDesignation.2')}
                                    </p>
                                    <Paragraph className="para">
                                        {t('our_team.founders.description.2')}
                                    </Paragraph>
                                </Col>
                            </Row>
                        </AnimatedComponent>
                        <AnimatedComponent>
                            <Row justify={'center'} className="FoundersRow">
                                <Col xs={24} sm={24} md={24} xl={6} xxl={6}>
                                    <img src={Suma} alt="Suma" />
                                </Col>
                                <Col
                                    className="FounderPara"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    xl={17}
                                    xxl={16}
                                >
                                    <Title className="founderName" level={5}>
                                        {t('our_team.founders.name.3')}
                                    </Title>
                                    <p>{t('our_team.founders.designation.3')}</p>
                                    <Paragraph className="para">
                                        {t('our_team.founders.description.3')}
                                    </Paragraph>
                                </Col>
                            </Row>
                        </AnimatedComponent>
                    </Row>
                </Row>
                <button>
                    <a href="/team">View more</a>
                </button>
            </Row>
        </React.Fragment>
    );
};

export default Founders;
