import React, { useEffect, useState } from 'react';
import { Button, Form, Input, message, Radio, Select, Space } from 'antd';
import { cloneDeep, filter } from 'lodash';
import UploadDocumentReg from '../../app-components/uploadDocument';
import { History } from 'history';
import {
    customPincodeValidator,
    generateFormData,
    gstinValidator,
    validateGst,
    validateInputField,
} from './utils';
import {
    addDeviceIdTokenInRegister,
    resetOtpState,
    setRegisterMsg,
    setResgiterVerifiedFlag,
    submitRegister,
    updateForm,
} from '../../store/registrationReducer/actions';
import { UserTypes } from '../../store/genericTypes';
import { customConsentValidator, documentLabelMapping } from '../constants';
import UploadDocument from '../../app-components/uploadDocument';
import { RuleObject } from 'antd/lib/form';
import PrimaryBtn from '../../app-components/primaryBtn';
import FormItem from 'antd/lib/form/FormItem';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import TextArea from 'antd/lib/input/TextArea';
import RegisterConfirmation from './registerConfirmationModal';
import RequestSubmittedPopup from './requestSubmittedPopup';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { promises } from 'dns';

type documentFormPropsModel = {
    userType: string;
    documents_list: Array<string>;
    subType: string;
    setShowMandatory: any;
    setComplusaryFormValues: any;
    history: History;
    setShowKycOne: any;
    showKycOne: any;
    category: any;
};

const SellerDocuments = (props: {
    documents_list: Array<any>;
    subType: string;
    userType: string;
    setShowMandatory: any;
    setComplusaryFormValues: any;
    history: History;
    setShowKycOne: any;
    showKycOne: any;
    category: any;
}) => {
    const {
        documents_list,
        subType,
        userType,
        setShowMandatory,
        setComplusaryFormValues,
        setShowKycOne,
        showKycOne,
    } = props;
    const [registeredUser, setRegisteredUser]: any = useState(0);
    const dispatch = useDispatch();
    const [listing, setlisting]: any = useState([]);
    const [showContinue, setShowContinue] = useState(true);
    const [showConfirmation, toggleShowConfirmation] = useState(false);
    const [registerFormValues, setRegisterFormValues] = useState({});
    const [showSubmitMsgPopup, toggleShowSubmitMsgPopup] = useState(false);
    const [addressForPin, setAddressForPin] = useState({ taluk: '', district: '', state: '' });

    const { history } = props;
    const registrationState = useSelector((state: RootState) => state.registration);
    const { configs, entityType, formData, registerResponse, isProcessing } = registrationState;
    const { category, phone_no } = formData;
    const date = new Date();
    console.log(date, 'date');
    const custmDate = moment(date).format('MM/DD/YYYY');
    const { t } = useTranslation('common');

    useEffect(() => {
        const data = {
            phone_no: phone_no,
            device_token: {
                deviceToken: (window as any).deviceId,
                date: custmDate,
            },
        };
        dispatch(addDeviceIdTokenInRegister(data));
    }, []);

    useEffect(() => {
        if (registerResponse.verified) {
            message.success('Sucessfully Registered! in please Log In');
            setShowKycOne(false);
            dispatch(setRegisterMsg(''));
            dispatch(setResgiterVerifiedFlag(false));
            toggleShowConfirmation(!showConfirmation);
        }
    }, [registerResponse.verified]);

    useEffect(() => {
        if (showKycOne === false) {
            form.resetFields();
        }
    }, [showKycOne]);

    let xyz: any = [];

    if (subType === 'Farmer') {
        xyz = filter(documents_list, { type: userType, sub_type: subType });
    } else {
        xyz = filter(documents_list, { type: userType, sub_type: subType, category: category });
    }
    let allDocumentsList: Array<string> = [];

    xyz.forEach((entity: any) => {
        allDocumentsList = [...allDocumentsList, ...entity.documents_list];
    });
    /* Creating a unique set */
    allDocumentsList = Array.from(new Set(allDocumentsList));
    let finallist: any = allDocumentsList[0];
    let registeredList = finallist[0];
    let unRegisteredList = finallist[1];

    const changeRegistrationStatus = (e: any) => {
        setRegisteredUser(e);
        if (e === 3 || e === 1) {
            setlisting(registeredList);
        } else {
            setlisting(unRegisteredList);
        }
    };

    const [form] = Form.useForm();

    const onConfirmRegister = () => {
        const registerDataPromise = generateFormData({
            formSubmitValues: cloneDeep(registerFormValues),
            userType: entityType,
            addressForPin,
        });
        registerDataPromise.then((registerFromData) => {
            dispatch(updateForm(registerFormValues as any));
            dispatch(submitRegister(registerFromData));
        });
    };

    const ComplusaryValues = (values: any) => {
        console.log(values);
        var documentdata;
        if (registeredUser === 1) {
            documentdata = { ...values, urd_status: 'registered' };
        } else if (registeredUser === 2) {
            documentdata = { ...values, urd_status: 'unregistered' };
        } else if (registeredUser === 3) {
            documentdata = { ...values, urd_status: 'composite' };
        } else {
            documentdata = values;
        }
        console.log(documentdata, 'document data once');
        var finalvalues = { ...formData, ...documentdata };
        if (subType === 'Individual' || subType === 'Farmer') {
            delete finalvalues['urd_status'];
            delete finalvalues['category'];
        }
        console.log(finalvalues, 'all the strings');
        setRegisterFormValues(finalvalues);
        toggleShowConfirmation(!showConfirmation);
    };

    return (
        <React.Fragment>
            <RegisterConfirmation
                isProcessing={isProcessing}
                registerResponse={registerResponse}
                showConfirmation={showConfirmation}
                onConfirmRegister={onConfirmRegister}
                toggleShowConfirmation={toggleShowConfirmation}
            />
            <Form
                onFinish={ComplusaryValues}
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 15 }}
                form={form}
            >
                {subType === 'Institution' ? (
                    <Form.Item
                        labelAlign="left"
                        label={t('Register.Type Of User')}
                        name="urd_status"
                        rules={[
                            {
                                required: true,
                                validator: (rule, value) => customConsentValidator(rule, value),
                            },
                        ]}
                    >
                        <Select
                            style={{ width: '200px' }}
                            placeholder="Select An Option"
                            value={registeredUser}
                            onChange={(e: any) => {
                                changeRegistrationStatus(e);
                            }}
                        >
                            <Select.Option value={1}>
                                {t('Register.Registered Dealer')}
                            </Select.Option>
                            <Select.Option value={2}>
                                {t('Register.Unregistered Dealer')}
                            </Select.Option>
                            <Select.Option value={3}>
                                {t('Register.Composite Dealer')}
                            </Select.Option>
                        </Select>
                    </Form.Item>
                ) : null}
                {listing?.map((documentName: any) => {
                    return (
                        <React.Fragment>
                            {documentLabelMapping.map((document) => {
                                const {
                                    formClassName,
                                    key,
                                    label,
                                    labelClassName,
                                    name,
                                    upload,
                                    uploadFormName,
                                    verify,
                                } = document;
                                let docsname = uploadFormName;

                                return key === documentName ? (
                                    <>
                                        <Form.Item
                                            labelAlign="left"
                                            label={
                                                <span
                                                    className={
                                                        (documentName === 'GSTIN' &&
                                                            (registeredUser === 1 ||
                                                                registeredUser === 3)) ||
                                                        (documentName === 'PAN' &&
                                                            registeredUser === 2) ||
                                                        (documentName === 'PAN' &&
                                                            (registeredUser === 1 ||
                                                                registeredUser === 3) &&
                                                            subType === 'Farmer')
                                                            ? labelClassName
                                                            : ''
                                                    }
                                                >
                                                    {t(`kyc.${label}`)}
                                                </span>
                                            }
                                        >
                                            <Form.Item
                                                labelAlign="left"
                                                name={name}
                                                className={formClassName}
                                                rules={[
                                                    {
                                                        validator: (rule, value) =>
                                                            validateInputField(
                                                                rule,
                                                                value,
                                                                documentName,
                                                            ),
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    style={{
                                                        textTransform: 'uppercase',
                                                        width: '200px',
                                                    }}
                                                    onChange={(e: any) => {
                                                        console.log(e.target.value);
                                                    }}
                                                />
                                            </Form.Item>

                                            <div className="verifybuttons">
                                                <Form.Item>
                                                    {upload ? (
                                                        <UploadDocument
                                                            name={docsname}
                                                            className="uploadDoc"
                                                        />
                                                    ) : null}
                                                </Form.Item>
                                            </div>
                                        </Form.Item>

                                        <Form.Item
                                            labelAlign="left"
                                            label={t('Register.Pin Code')}
                                            name="zip"
                                            required={true}
                                            className="pinCode"
                                            rules={[
                                                {
                                                    validator: (rule, value) =>
                                                        customPincodeValidator(
                                                            rule,
                                                            value,
                                                            setAddressForPin,
                                                        ),
                                                },
                                            ]}
                                        >
                                            <Space direction="horizontal" size={4}>
                                                <Input
                                                    style={{ width: '70px' }}
                                                    className="custom-input"
                                                />
                                                <div>
                                                    <p style={{ paddingTop: '5px' }}>
                                                        {addressForPin.taluk}{' '}
                                                        {addressForPin.district}{' '}
                                                        {addressForPin.state}
                                                    </p>
                                                </div>
                                            </Space>
                                        </Form.Item>

                                        <Form.Item></Form.Item>

                                        <Form.Item
                                            labelAlign="left"
                                            label={t('Register.Address')}
                                            className="address"
                                            name="address1"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your Address!',
                                                },
                                            ]}
                                        >
                                            <TextArea
                                                rows={4}
                                                maxLength={100}
                                                style={{
                                                    width: '600px',
                                                }}
                                            ></TextArea>
                                        </Form.Item>
                                    </>
                                ) : null;
                            })}
                        </React.Fragment>
                    );
                })}
                {subType === 'Farmer' && (
                    <>
                        <Form.Item
                            label={
                                <span className={'required-form-field'}>{t('kyc.PAN Number')}</span>
                            }
                        >
                            <Form.Item
                                labelAlign="left"
                                name={'pan'}
                                rules={[
                                    {
                                        validator: (rule, value) =>
                                            validateInputField(rule, value, 'PAN'),
                                    },
                                ]}
                            >
                                <Input
                                    style={{
                                        textTransform: 'uppercase',
                                        width: '200px',
                                    }}
                                    onChange={(e: any) => {
                                        console.log(e.target.value);
                                    }}
                                />
                            </Form.Item>

                            <div className="verifybuttons">
                                <Form.Item>
                                    <UploadDocument name={'pan_card'} className="uploadDoc" />
                                </Form.Item>
                            </div>
                        </Form.Item>

                        <Form.Item
                            labelAlign="left"
                            label={t('Register.Pin Code')}
                            name="zip"
                            required={true}
                            className="pinCode"
                            rules={[
                                {
                                    validator: (rule, value) =>
                                        customPincodeValidator(rule, value, setAddressForPin),
                                },
                            ]}
                        >
                            <Space direction="horizontal" size={4}>
                                <Input style={{ width: '70px' }} className="custom-input" />
                                <div>
                                    <p style={{ paddingTop: '5px' }}>
                                        {addressForPin.taluk} {addressForPin.district}{' '}
                                        {addressForPin.state}
                                    </p>
                                </div>
                            </Space>
                        </Form.Item>
                        <Form.Item></Form.Item>
                        <Form.Item
                            labelAlign="left"
                            label={t('Register.Address')}
                            className="address"
                            name="address1"
                            rules={[{ required: true, message: 'Please input your Address!' }]}
                        >
                            <TextArea
                                rows={4}
                                maxLength={100}
                                style={{
                                    width: '600px',
                                }}
                            ></TextArea>
                        </Form.Item>
                    </>
                )}
                <div style={{ textAlign: 'center' }}>
                    <PrimaryBtn
                        style={{ width: '200px' }}
                        className="margin-l-r-1em center"
                        htmlType="submit"
                        content={<b>{t('Register.content5')}</b>}
                    />
                </div>
            </Form>
        </React.Fragment>
    );
};

const BuyerDocuments = (props: {
    documents_list: Array<any>;
    subType: string;
    userType: string;
    setShowMandatory: any;
    setComplusaryFormValues: any;
    history: History;
    setShowKycOne: any;
    showKycOne: any;
    category: any;
}) => {
    const {
        documents_list,
        subType,
        userType,
        setShowMandatory,
        setComplusaryFormValues,
        setShowKycOne,
        showKycOne,
    } = props;
    const [registeredUser, setRegisteredUser]: any = useState(null);
    const dispatch = useDispatch();
    const [listing, setlisting]: any = useState([]);
    const [showContinue, setShowContinue] = useState(true);
    const [showConfirmation, toggleShowConfirmation] = useState(false);
    const [registerFormValues, setRegisterFormValues] = useState({});
    const [showSubmitMsgPopup, toggleShowSubmitMsgPopup] = useState(false);
    const [addressForPin, setAddressForPin] = useState({ taluk: '', district: '', state: '' });

    const { history } = props;
    const registrationState = useSelector((state: RootState) => state.registration);
    const { configs, entityType, formData, registerResponse, isProcessing } = registrationState;
    const { category, phone_no } = formData;
    const date = new Date();
    console.log(date, 'date');
    const custmDate = moment(date).format('MM/DD/YYYY');
    const { t } = useTranslation('common');

    useEffect(() => {
        const data = {
            phone_no: phone_no,
            device_token: {
                deviceToken: (window as any).deviceId,
                date: custmDate,
            },
        };
        dispatch(addDeviceIdTokenInRegister(data));
    }, []);

    useEffect(() => {
        if (registerResponse.verified) {
            message.success('Sucessfully Registered! in please Log In');
            setShowKycOne(false);
            dispatch(setRegisterMsg(''));
            dispatch(setResgiterVerifiedFlag(false));
            toggleShowConfirmation(!showConfirmation);
        }
    }, [registerResponse.verified]);

    useEffect(() => {
        if (showKycOne === false) {
            form.resetFields();
        }
    }, [showKycOne]);

    let xyz: any = [];

    if (subType === 'Individual') {
        xyz = filter(documents_list, { type: userType, sub_type: subType });
    } else {
        xyz = filter(documents_list, { type: userType, sub_type: subType, category: category });
    }
    let allDocumentsList: Array<string> = [];

    xyz.forEach((entity: any) => {
        allDocumentsList = [...allDocumentsList, ...entity.documents_list];
    });

    /* Creating a unique set */
    allDocumentsList = Array.from(new Set(allDocumentsList));
    let finallist: any = allDocumentsList[0];
    let registeredList = finallist[0];
    let unRegisteredList = finallist[1];

    const changeRegistrationStatus = (e: any) => {
        console.log(e, 'e');
        setRegisteredUser(e);
        console.log('registeredUser', registeredUser);
        if (e === 3 || e === 1) {
            setlisting(registeredList);
        } else {
            setlisting(unRegisteredList);
        }
    };

    const [form] = Form.useForm();

    const onConfirmRegister = () => {
        const registerDataPromise = generateFormData({
            formSubmitValues: cloneDeep(registerFormValues),
            userType: entityType,
            addressForPin,
        });
        registerDataPromise.then((registerFromData) => {
            dispatch(updateForm(registerFormValues as any));
            dispatch(submitRegister(registerFromData));
        });
    };

    const ComplusaryValues = (values: any) => {
        console.log(values);
        let documentdata;
        if (registeredUser === 1) {
            documentdata = { ...values, urd_status: 'registered' };
        } else if (registeredUser === 2) {
            documentdata = { ...values, urd_status: 'unregistered' };
        } else if (registeredUser === 3) {
            documentdata = { ...values, urd_status: 'composite' };
        } else {
            documentdata = values;
        }
        console.log(documentdata, 'document data once');
        var finalvalues = { ...formData, ...documentdata };
        if (subType === 'Individual') {
            delete finalvalues['urd_status'];
            delete finalvalues['category'];
        }
        console.log(finalvalues, 'all the strings');
        setRegisterFormValues(finalvalues);
        toggleShowConfirmation(!showConfirmation);
    };

    return (
        <React.Fragment>
            <RegisterConfirmation
                isProcessing={isProcessing}
                registerResponse={registerResponse}
                showConfirmation={showConfirmation}
                onConfirmRegister={onConfirmRegister}
                toggleShowConfirmation={toggleShowConfirmation}
            />
            <Form
                onFinish={ComplusaryValues}
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 15 }}
                form={form}
            >
                {subType === 'Institution' ? (
                    <Form.Item
                        labelAlign="left"
                        label={t('Register.Type Of User')}
                        name="urd_status"
                        rules={[
                            {
                                required: true,
                                validator: (rule, value) => customConsentValidator(rule, value),
                            },
                        ]}
                    >
                        <Select
                            style={{ width: '200px' }}
                            placeholder="Select An Option"
                            value={registeredUser}
                            onChange={(e: any) => {
                                changeRegistrationStatus(e);
                            }}
                        >
                            <Select.Option value={1}>
                                {t('Register.Registered Dealer')}
                            </Select.Option>
                            <Select.Option value={2}>
                                {t('Register.Unregistered Dealer')}
                            </Select.Option>
                            <Select.Option value={3}>
                                {t('Register.Composite Dealer')}
                            </Select.Option>
                        </Select>
                    </Form.Item>
                ) : null}
                {listing.map((documentName: any) => {
                    return (
                        <React.Fragment>
                            {documentLabelMapping.map((document) => {
                                const {
                                    formClassName,
                                    key,
                                    label,
                                    labelClassName,
                                    name,
                                    upload,
                                    uploadFormName,
                                    verify,
                                } = document;
                                let docsname = uploadFormName;

                                return key === documentName ? (
                                    <>
                                        <Form.Item
                                            labelAlign="left"
                                            label={
                                                <span
                                                    className={
                                                        (documentName === 'GSTIN' &&
                                                            (registeredUser === 1 ||
                                                                registeredUser === 3)) ||
                                                        (documentName === 'PAN' &&
                                                            registeredUser === 2) ||
                                                        (documentName === 'PAN' &&
                                                            (registeredUser === 1 ||
                                                                registeredUser === 3) &&
                                                            subType === 'Individual')
                                                            ? labelClassName
                                                            : ''
                                                    }
                                                >
                                                    {t(`kyc.${label}`)}
                                                </span>
                                            }
                                        >
                                            <Form.Item
                                                labelAlign="left"
                                                name={name}
                                                className={formClassName}
                                                rules={[
                                                    {
                                                        validator: (rule, value) =>
                                                            validateInputField(
                                                                rule,
                                                                value,
                                                                documentName,
                                                            ),
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    style={{
                                                        textTransform: 'uppercase',
                                                        width: '200px',
                                                    }}
                                                    onChange={(e: any) => {
                                                        console.log(e.target.value);
                                                    }}
                                                />
                                            </Form.Item>

                                            <div className="verifybuttons">
                                                <Form.Item>
                                                    {upload ? (
                                                        <UploadDocument
                                                            name={docsname}
                                                            className="uploadDoc"
                                                        />
                                                    ) : null}
                                                </Form.Item>
                                            </div>
                                        </Form.Item>

                                        <Form.Item
                                            labelAlign="left"
                                            label={t('Register.Pin Code')}
                                            name="zip"
                                            required={true}
                                            className="pinCode"
                                            rules={[
                                                {
                                                    validator: (rule, value) =>
                                                        customPincodeValidator(
                                                            rule,
                                                            value,
                                                            setAddressForPin,
                                                        ),
                                                },
                                            ]}
                                        >
                                            <Space direction="horizontal" size={4}>
                                                <Input
                                                    style={{ width: '70px' }}
                                                    className="custom-input"
                                                />
                                                <div>
                                                    <p style={{ paddingTop: '5px' }}>
                                                        {addressForPin.taluk}{' '}
                                                        {addressForPin.district}{' '}
                                                        {addressForPin.state}
                                                    </p>
                                                </div>
                                            </Space>
                                        </Form.Item>

                                        <Form.Item></Form.Item>

                                        <Form.Item
                                            labelAlign="left"
                                            label={t('Register.Address')}
                                            className="address"
                                            name="address1"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your Address!',
                                                },
                                            ]}
                                        >
                                            <TextArea
                                                rows={4}
                                                maxLength={100}
                                                style={{
                                                    width: '600px',
                                                }}
                                            ></TextArea>
                                        </Form.Item>
                                    </>
                                ) : null;
                            })}
                        </React.Fragment>
                    );
                })}
                {subType === 'Individual' && (
                    <>
                        <Form.Item
                            label={
                                <span className={'required-form-field'}>{t('kyc.PAN Number')}</span>
                            }
                        >
                            <Form.Item
                                labelAlign="left"
                                name={'pan'}
                                rules={[
                                    {
                                        validator: (rule, value) =>
                                            validateInputField(rule, value, 'PAN'),
                                    },
                                ]}
                            >
                                <Input
                                    style={{
                                        textTransform: 'uppercase',
                                        width: '200px',
                                    }}
                                    onChange={(e: any) => {
                                        console.log(e.target.value);
                                    }}
                                />
                            </Form.Item>

                            <div className="verifybuttons">
                                <Form.Item>
                                    <UploadDocument name={'pan_card'} className="uploadDoc" />
                                </Form.Item>
                            </div>
                        </Form.Item>

                        <Form.Item
                            labelAlign="left"
                            label={t('Register.Pin Code')}
                            name="zip"
                            required={true}
                            className="pinCode"
                            rules={[
                                {
                                    validator: (rule, value) =>
                                        customPincodeValidator(rule, value, setAddressForPin),
                                },
                            ]}
                        >
                            <Space direction="horizontal" size={4}>
                                <Input style={{ width: '70px' }} className="custom-input" />
                                <div>
                                    <p style={{ paddingTop: '5px' }}>
                                        {addressForPin.taluk} {addressForPin.district}{' '}
                                        {addressForPin.state}
                                    </p>
                                </div>
                            </Space>
                        </Form.Item>
                        <Form.Item></Form.Item>
                        <Form.Item
                            labelAlign="left"
                            label={t('Register.Address')}
                            className="address"
                            name="address1"
                            rules={[{ required: true, message: 'Please input your Address!' }]}
                        >
                            <TextArea
                                rows={4}
                                maxLength={100}
                                style={{
                                    width: '600px',
                                }}
                            ></TextArea>
                        </Form.Item>
                    </>
                )}
                <div style={{ textAlign: 'center' }}>
                    {/* <Button>cancel</Button> */}

                    <PrimaryBtn
                        style={{ width: '200px' }}
                        className="margin-l-r-1em center"
                        htmlType="submit"
                        content={<b>{t('Register.content5')}</b>}
                    />
                </div>
            </Form>
        </React.Fragment>
    );
};

const DocumentsUploadComponents = (documentFormProps: documentFormPropsModel) => {
    const {
        userType,
        documents_list,
        subType,
        setShowMandatory,
        setComplusaryFormValues,
        history,
        setShowKycOne,
        showKycOne,
        category,
    } = documentFormProps;
    return userType === UserTypes.BUYER ? (
        <BuyerDocuments
            showKycOne={showKycOne}
            setShowKycOne={setShowKycOne}
            history={history}
            subType={subType}
            userType={userType}
            documents_list={documents_list}
            setShowMandatory={setShowMandatory}
            setComplusaryFormValues={setComplusaryFormValues}
            category={category}
        />
    ) : (
        <SellerDocuments
            showKycOne={showKycOne}
            setShowKycOne={setShowKycOne}
            history={history}
            subType={subType}
            userType={userType}
            documents_list={documents_list}
            setShowMandatory={setShowMandatory}
            setComplusaryFormValues={setComplusaryFormValues}
            category={category}
        />
    );
};

export default DocumentsUploadComponents;
