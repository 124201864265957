import React from 'react';
import { useInView } from 'react-intersection-observer';
import { useSpring, animated } from 'react-spring';

const AnimatedKeyComponents = ({ children }: any) => {
    const [inViewRef, inView] = useInView({
        triggerOnce: true,
    });

    const animationProps = useSpring({
        from: { opacity: 0, transform: 'translateY(100px) scaleX(-1)' },
        to: {
            opacity: inView ? 1 : 0,
            transform: inView ? 'translateY(0) scaleX(1)' : 'translateY(100px) scaleX(-1)',
        },
        config: { duration: 2500 },
    });

    return (
        <animated.div ref={inViewRef} style={animationProps}>
            {children}
        </animated.div>
    );
};

export default AnimatedKeyComponents;
