import React from 'react';
import './landingPage.scss';
import JIT from '../static/assets/jit.png';
import SVCE from '../static/assets/svce.png';
import Title from 'antd/lib/typography/Title';
import Group from '../static/assets/group.png';
import IIITB from '../static/assets/iiitb.png';
import MEITY from '../static/assets/meity.png';
// import SKIT from '../static/assets/skit.png';
import C_Camp from '../static/assets/c-camp.png';
import UASB from '../static/assets/uas_blore.png';
import RAFTAAR from '../static/assets/raftaar.png';
import UASD from '../static/assets/uas_dharawad.png';
import BlueLine from '../static/assets/blue_line.png';
import INDIA from '../static/assets/startup_india.png';
import KARNATAKA from '../static/assets/startup_karnataka.png';
import { Carousel } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

export default function Partners() {
    return (
        <React.Fragment>
            <div id="partners" className="partners">
                <div className="blue-box">
                    <img src={Group} />
                </div>
                <Title className="partnerTitle">
                    <span className="first_word">Our</span> Partners
                </Title>
                <img src={BlueLine} alt="blue_line" />
                <div className="web-visible-partners">
                <Carousel autoplay arrows={true} prevArrow={<LeftOutlined />} nextArrow={<RightOutlined />} dots={false}
                >
                    <div className='inside-carousel'>
                        <img src={C_Camp}></img>
                        <img src={MEITY}></img>
                        <img src={RAFTAAR}></img>
                        <img src={KARNATAKA}></img>
                        <img src={INDIA}></img>
                    </div>
                    <div className='inside-carousel'>
                        <img src={JIT}></img>
                        <img src={SVCE}></img>
                        <img src={UASD}></img>
                        <img src={UASB}></img>
                        <img src={IIITB}></img>
                    </div>
                </Carousel>
                </div>

                <div className="partners-phone-display">
                    <Carousel autoplay dots={false}>
                        <div className="partner-carousel-item">
                            <img src={C_Camp}></img>
                        </div>
                        <div className="partner-carousel-item">
                            <img src={MEITY}></img>
                        </div>
                        <div className="partner-carousel-item">
                            <img src={RAFTAAR}></img>
                        </div>
                        <div className="partner-carousel-item">
                            <img src={KARNATAKA}></img>
                        </div>
                        <div className="partner-carousel-item">
                            <img src={INDIA}></img>
                        </div>
                        <div className="partner-carousel-item">
                            <img src={JIT}></img>
                        </div>
                        <div className="partner-carousel-item">
                            <img src={SVCE}></img>
                        </div>
                        <div className="partner-carousel-item">
                            <img src={UASD}></img>
                        </div>
                        <div className="partner-carousel-item">
                            <img src={UASB}></img>
                        </div>
                        <div className="partner-carousel-item">
                            <img src={IIITB}></img>
                        </div>
                    </Carousel>
                </div>
            </div>
        </React.Fragment>
    );
}
