import { Button, Typography, Image } from 'antd';
import { isEmpty } from 'lodash';
import React from 'react';
import PrimaryBtn from '../../app-components/primaryBtn';
import confirmationPopup from '../confirmationPopup';
import { showCropImage } from '../constants';
import { openAdditionalInfo } from '../openAdditionalInfo';
import ViewCropImages from '../viewCropImages';
import ConnectMatches from './connectMatches';

const { Title, Text } = Typography;

const CombineData = (props: any) => {
    const { combinedBestData, isChild, componentCallBacks, setDisplayConnect, is_buyer } = props;
    console.log(combinedBestData, 'combinedBestData in combineBestData');
    console.log(isChild, 'isChild');
    const { showCropDetailsModal, populateCropDetails, rejectMatch, showConnectModal } =
        componentCallBacks;
    const combinedMatchOne = combinedBestData?.combinedMatchOne;
    const combinedMatchTwo = combinedBestData?.combinedMatchTwo;

    if (combinedBestData) {
        const produce: any = combinedMatchOne?.produce;
        var [masterCategory = '', produceCateogry = '', cropType = '', grade = '']: any =
            produce?.split('-');
        var imageSrc = showCropImage(produceCateogry);
        var buyerTotalPrice =
            combinedMatchOne.buyer_total_price + combinedMatchTwo.buyer_total_price;
        var buyerMatchedQty = combinedMatchOne.matched_quantity + combinedMatchTwo.matched_quantity;
        const fullFillment: any =
            combinedMatchOne.buyer_remaining_quantity + combinedMatchTwo.buyer_remaining_quantity;
        var FulfilmentComp: any = () =>
            fullFillment <= 0 ? (
                <Text className="full-match">FULL</Text>
            ) : (
                <Text className="partial-match">PARTIAL</Text>
            );
    }

    return (
        <React.Fragment>
            {!isEmpty(combinedBestData) ? (
                <table className="bestMatchesFullTable background">
                    <tbody>
                        <tr>
                            <td className="sellrId">Combination Match</td>
                            <td className="producePhotoCat">
                                <React.Fragment>
                                    <div className="display-flex-row align-center">
                                        {!isChild && (
                                            <Image
                                                preview={false}
                                                src={imageSrc}
                                                className="table-crop-image"
                                            />
                                        )}
                                        <div className="gradeCategory">
                                            <Title className="more-matches-text" level={5}>
                                                {produceCateogry.trim()} - {cropType.trim()}
                                            </Title>
                                            <Text className="more-matches-text">
                                                {grade.trim()}
                                            </Text>
                                        </div>
                                    </div>
                                    <div className="additionalContent">
                                        {!isEmpty(combinedMatchOne.additional_info) && (
                                            <Button
                                                type="link"
                                                disabled={isEmpty(combinedMatchOne.additional_info)}
                                                onClick={() =>
                                                    openAdditionalInfo(
                                                        combinedMatchOne.additional_info,
                                                    )
                                                }
                                            >
                                                Additional Info
                                            </Button>
                                        )}
                                        {!isEmpty(combinedMatchOne.cropImageList) && (
                                            <ViewCropImages
                                                list={combinedMatchOne.cropImageList}
                                                disablePhotos={false}
                                            />
                                        )}
                                    </div>
                                </React.Fragment>
                            </td>
                            <td className="sellrId sellerDisplayFlex">
                                <div>
                                    {' '}
                                    {buyerMatchedQty}{' '}
                                    {combinedMatchOne.buyer_unit !== 'Tonne'
                                        ? combinedMatchOne.buyer_unit
                                        : 'Ton'}{' '}
                                </div>
                                <div>
                                    <FulfilmentComp />
                                </div>
                            </td>
                            <td className="sellrId">{buyerTotalPrice}</td>
                            <td className="sellrId">location</td>
                            <td className="sellrId">
                                <Button
                                    className="view-details-button"
                                    type="link"
                                    onClick={() => {
                                        populateCropDetails(combinedBestData);
                                        showCropDetailsModal(true);
                                    }}
                                >
                                    View Details
                                </Button>
                            </td>
                            <td className="sellrId">
                                <PrimaryBtn
                                    className="vikas-btn-radius connect-button"
                                    onClick={() => {
                                        showConnectModal(true);
                                        populateCropDetails(combinedBestData);
                                    }}
                                    content={'Connect'}
                                />
                            </td>
                            <td className="sellrId">
                                {isChild ? null : (
                                    <Button
                                        className="reject-button"
                                        type="link"
                                        danger
                                        onClick={() =>
                                            confirmationPopup(
                                                'reject',
                                                rejectMatch,
                                                combinedBestData,
                                            )
                                        }
                                    >
                                        Reject
                                    </Button>
                                )}
                            </td>
                        </tr>
                    </tbody>
                </table>
            ) : null}
        </React.Fragment>
    );
};

export default CombineData;
