import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Input, Typography } from 'antd';
import { CaretRightFilled } from '@ant-design/icons';
import { filter, isEmpty, isUndefined, toUpper } from 'lodash';

import { documentLabelMapping, fieldLayout, inputFieldValue, uploadFiledValue } from './constants';
import { validateInputField, ViewDocument } from './utils';

import { UserTypes } from '../store/genericTypes';
import { getUserFiles } from '../store/loginReducer/actions';
import UploadDocument from '../app-components/uploadDocument';
import { RootState } from '../store/rootReducer';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

type DocumentsUploadedProps = {
    config: any;
    kycFlag: string;
    setDisableSave: Function;
    userDetails: any;
    setMandatoryFields: Function;
};

const DocumentsUploaded = (props: DocumentsUploadedProps) => {
    const { config, kycFlag, setDisableSave, userDetails, setMandatoryFields } = props;
    const userType = userDetails.is_buyer ? UserTypes.BUYER : UserTypes.SELLER;
    const subType = userType === UserTypes.BUYER ? userDetails.buyer_type : userDetails.seller_type;
    const loginState = useSelector((state: RootState) => state.loginUser);
    const urdStatus = loginState?.urd_status;

    const [showDocument, setShowDocument] = useState(false);
    const [imageSrc, setImageSrc] = useState();
    const [isPDF, setPDF] = useState(false);
    const dispatch = useDispatch();
    const { t } = useTranslation('common');

    const xyz = isEmpty(userDetails.category)
        ? filter(config, { type: userType, sub_type: subType })
        : filter(config, { type: userType, sub_type: subType, category: userDetails.category });
    let allDocumentsList: Array<string> = [];
    xyz.forEach((entity) => {
        allDocumentsList = [...allDocumentsList, ...entity.documents_list];
    });

    let finallist: any = allDocumentsList[1];
    const theFinalList: any = urdStatus === 'unregistered' ? finallist[1] : finallist[0];

    return (
        <React.Fragment>
            {theFinalList.map((documentName: any, index: number) => {
                return (
                    <div key={index} className={kycFlag === 'incomplete' ? `kyc-required` : ``}>
                        {documentLabelMapping.map((document, index: number) => {
                            const { key, label, name, upload, uploadFormName } = document;
                            const value = inputFieldValue(name, userDetails);
                            console.log(value, 'value');
                            const uploadValue = upload
                                ? uploadFiledValue(uploadFormName, userDetails)
                                : null;

                            return key === documentName ? (
                                <Form.Item
                                    key={index}
                                    label={
                                        <span className="kyc-form-label">
                                            {isEmpty(value) && (
                                                <CaretRightFilled
                                                    className="required-arrow"
                                                    style={{ color: '#FF9900' }}
                                                />
                                            )}
                                            {t(`kyc.${label}`)}
                                        </span>
                                    }
                                    {...fieldLayout}
                                    className="margin-zero"
                                    onReset={() => console.log('on reset')}
                                >
                                    {isEmpty(value) ? (
                                        <Form.Item
                                            className="margin-zero"
                                            name={isEmpty(value) ? name : undefined}
                                            rules={[
                                                {
                                                    validator: (rule, value) =>
                                                        validateInputField(rule, value, name),
                                                },
                                            ]}
                                        >
                                            {name === 'rentalAgreement' ? null : (
                                                <Input
                                                    className="custom-input kyc-input-field"
                                                    style={{ textTransform: 'uppercase' }}
                                                    defaultValue={value}
                                                    contentEditable
                                                    onChange={() => {
                                                        setDisableSave(false);
                                                        setMandatoryFields(true);
                                                    }}
                                                />
                                            )}
                                        </Form.Item>
                                    ) : name === 'rentalAgreement' ? null : (
                                        <Text>:{toUpper(value)}</Text>
                                    )}
                                    {upload && (
                                        <React.Fragment>
                                            {isEmpty(uploadValue) ? (
                                                <UploadDocument
                                                    className="margin-zero"
                                                    name={
                                                        isEmpty(uploadValue)
                                                            ? uploadFormName
                                                            : undefined
                                                    }
                                                    onChange={() => {
                                                        setDisableSave(false);
                                                        setMandatoryFields(true);
                                                    }}
                                                />
                                            ) : (
                                                <Button
                                                    type="link"
                                                    onClick={() => {
                                                        dispatch(
                                                            getUserFiles(
                                                                uploadValue.doc_key,
                                                                setImageSrc,
                                                                setPDF,
                                                            ),
                                                        );
                                                        setShowDocument(true);
                                                    }}
                                                >
                                                    &nbsp; &nbsp;{t('Register.View Document')}
                                                </Button>
                                            )}
                                        </React.Fragment>
                                    )}
                                </Form.Item>
                            ) : null;
                        })}
                    </div>
                );
            })}
            {showDocument && (
                <ViewDocument url={imageSrc} setShowDocument={setShowDocument} isPDF={isPDF} />
            )}
        </React.Fragment>
    );
};

export default DocumentsUploaded;
