import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Spin } from 'antd';

import {
    deviceIdPayload,
    getUserCompleteDetails,
    getUserDetails,
} from '../store/loginReducer/actions';
import { RootState } from '../store/rootReducer';
import { UserStateModel } from '../store/loginReducer/types';
import { routesMap } from '../constants';
import moment from 'moment-timezone';
import { isEmpty, isUndefined } from 'lodash';
import Home from '../landing-page/home';
import { LOGOUT_URL } from '../store/api';

const { buyer_ui, seller_ui } = routesMap;

const ValidateUserAuthentication = (props: any) => {
    const { history } = props;
    const loginState = useSelector((state: RootState) => state.loginUser);
    const { signInState, is_seller, is_buyer, amplifyResponse, phone_no } = loginState;
    const dispatch = useDispatch();
    const date = new Date();
    console.log(date, 'date');
    // const custmDate = moment(date).format('MM/DD/YYYY');
    const custmDate = '07/04/2023';

    // Get all the keys from localStorage
    const keys = Object.keys(localStorage);

    // Create an array to hold the values
    const values = [];

    useEffect(() => {
        console.log('im here in validateUser');
        dispatch(getUserDetails());
    }, []);

    useEffect(() => {
        if (signInState.isVerified && amplifyResponse) {
            if (is_seller) {
                if (loginState?.requested_transfer_phone_no) {
                    window.alert(
                        'Your request for phone number change is in process,please login after sometime with new phone number',
                    );
                    window.location.href = LOGOUT_URL;
                    localStorage.clear();
                } else {
                    history.push(seller_ui);
                }
            } else if (is_buyer) {
                if (loginState?.requested_transfer_phone_no) {
                    window.alert(
                        'Your request for phone number change is in process,please login after sometime with new phone number',
                    );
                    window.location.href = LOGOUT_URL;
                    localStorage.clear();
                }
                else{
                    history.push(buyer_ui);
                }
            }
        }
    }, [signInState.isVerified, amplifyResponse]);

    useEffect(() => {
        if (loginState?.phone_no) {
            const data = {
                phone_no: loginState?.phone_no,
                device_token: {
                    deviceToken: (window as any).deviceId,
                    date: custmDate,
                },
            };
            dispatch(deviceIdPayload(data));
        }
    }, [loginState?.phone_no]);

    return (
        <Spin spinning={!signInState.hasError}>
            <Alert
                className="text-align-center"
                banner
                message={
                    signInState.hasError
                        ? signInState.msg
                        : 'Please wait while we are validating the user'
                }
                description={
                    signInState.hasError
                        ? signInState.msg
                        : 'Please wait while we are validating the user'
                }
                type={signInState.hasError ? 'error' : 'info'}
            />
        </Spin>
    );
};

export default ValidateUserAuthentication;
