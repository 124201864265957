import React from 'react';
import { Button, Col, Input, Row, Space, Typography, Form } from 'antd';
import { EnvironmentFilled, MailFilled, PhoneFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import emailjs from '@emailjs/browser';

import { contactUs } from '../constants';
import { LeftOutlined } from '@ant-design/icons';
const { Text, Title } = Typography;
const { TextArea } = Input;

const ContactUs = () => {
    const { t } = useTranslation('common');

    const validatePhone = (_rule: any, value: any) => {
        const regExp = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s/0-9]*$/g;

        if (!value) {
            return Promise.reject(`please enter your Phone number!`);
        } else if (!regExp.test(value)) {
            return Promise.reject(`please enter a valid number`);
        } else {
            return Promise.resolve();
        }
    };
    const [form] = Form.useForm();

    function onFinish(values: any) {
        emailjs
            .sendForm(
                'service_l82pyum',
                'template_us5cewg',
                '#miform',
                'user_YM5RXaCp7C9RE9C5Dki87',
            )
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.log(err);
            });
        form.resetFields();
    }

    return (
        <div id="contactUs">
            <div className="team_back_button">
                <Button
                    size="small"
                    icon={
                        <span className="gradient-icon">
                            <LeftOutlined />
                        </span>
                    }
                    href="/"
                >
                    <span className="gradient-text">Back</span>
                </Button>
            </div>
            <div className='aboutUs'>
                <Title>About VikasBandhu</Title>
                <p>
                    Agriculture is the lifeline for most economies including India. It is a vast
                    domain with boundless opportunities to imbibe technology driven solutions. The
                    Agricultural landscape is at the tipping point for technology adoption, backed
                    up by unparalleled digital access, timely government actions and support from
                    the investors. Aided by Market Research done in Karnataka early on, We narrowed
                    our attention in the post harvest space. We have developed an agriculture
                    e-market place called VikasBandhu. It is a technology platform enabled with an
                    end-to-end solution aimed to ensure that helping the farming community to make
                    informed decisions.. We aim to help farmer and farmer self-help groups directly
                    sell their produce in an F2B (FPO to Business) or B2B model.. Please refer to
                    the video<a>(https://youtu.be/Ar1V4WtOWcw)</a> for more about our unique Technology
                    solution
                </p>
            </div>
            <Title className="contact-us-title">{t('contact_us_page.title')}</Title>
            <Row justify={'space-around'} className="contactWrapper">
                <Col sm={24} xs={24} md={24} xl={24} xxl={24} className="contact-us-info">
                    <Space direction="vertical">
                        <div>
                            <PhoneFilled className="contact-us-icon" />
                            <Text className="contact-details">{contactUs.phoneNumber}</Text>
                        </div>
                        <div>
                            <MailFilled className="contact-us-icon" />
                            <Text className="contact-details">{contactUs.emailId}</Text>
                        </div>
                        <div>
                            <EnvironmentFilled className="contact-us-icon" />
                            <address className="contact-details contact-address">
                                <b>{t('contact_us_page.address1')}</b>
                                <br />
                                {t('contact_us_page.address2')}
                                <br />
                                {t('contact_us_page.address3')}
                                <br />
                                {t('contact_us_page.address4')}
                            </address>
                        </div>
                        <div className="mapouter">
                            <div className="gmap_canvas">
                                <iframe
                                    className="gmap_iframe"
                                    width="100%"
                                    frameBorder="0"
                                    scrolling="no"
                                    src="https://maps.google.com/maps?width=387&amp;height=179&amp;hl=en&amp;q=617, samparkbindhu pvt.ltd bangalore&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                                ></iframe>
                                <a href="https://kokagames.com/">FNF</a>
                            </div>
                        </div>
                    </Space>
                </Col>
                <Col sm={24} xs={24} md={24} xl={24} xxl={24} className="write-feedback">
                    <div className="feedback-form">
                        <Title className="write-feedback-title" level={4}>
                            {t('contact_us_page.contact_form.title')}
                        </Title>
                        <Form form={form} id="miform" onFinish={onFinish}>
                            <Form.Item
                                name="fullName"
                                rules={[
                                    {
                                        required: true,
                                        message: 'please enter a valid name',
                                    },
                                    {
                                        min: 3,
                                    },
                                ]}
                            >
                                <Input name="fullName" placeholder="Full Name" />
                            </Form.Item>
                            <Form.Item name="email" rules={[{ required: true }, { type: 'email' }]}>
                                <Input name="email" placeholder="Email" />
                            </Form.Item>
                            <Form.Item
                                name="phone"
                                rules={[
                                    {
                                        required: true,
                                        validator: (_rule, value) => validatePhone(_rule, value),
                                    },
                                    { min: 10 },
                                ]}
                            >
                                <Input name="phone" placeholder="Phone Number" />
                            </Form.Item>
                            <Form.Item name="message">
                                <TextArea
                                    name="message"
                                    placeholder="Message"
                                    autoSize={{ minRows: 3, maxRows: 5 }}
                                />
                            </Form.Item>
                            <Button block htmlType="submit">
                                {t('landing_page.actions.submit')}
                            </Button>
                        </Form>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default ContactUs;
