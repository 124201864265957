import React from 'react';
import { isEmpty } from 'lodash';

export const PAN_10_DIGIT_MSG = 'Enter PAN number of 10 characters!';
export const PAN_INVALID = 'Invalid PAN number!';

export const AADHAAR_REQUIRED_MSG = 'Please provide your Aadhaar card Number!';
export const AADHAAR_12_DIGIT_MSG = 'Enter Aadhaar number of 12 digits!';
export const AADHAAR_NOT_NUMBER = 'Aadhaar should be a number!';

export const IFSC_11_DIGIT_MSG = 'Enter IFSC code of 11 characters!';
export const IFSC_INVALID = 'Invalid IFSC code!';

export const NAME_REQUIRED_MSG = 'Please provide';
export const NAME_INVALID = 'should contain alphabets only!';

export const ACCOUNT_NUMBER_MIN_DIGITS_MSG = 'Account Number must have at least 9 digits!';
export const ACCOUNT_NUMBER_MAX_DIGITS_MSG = 'Account Number can not exceed 18 digits!';
export const ACCOUNT_NUMBER_INVALID = 'Account Number should be a number!';

export const CONFIRM_ACCOUNT_MISMATCH = 'It should be same as Account Number!';

// export const UPI_ID_INVALID_MSG = 'Invalid UPI Id!';

export const EMAIL_REQUIRED_MSG = 'Please provide your email id!';
export const EMAIL_INVALID_MSG = 'Invalid email id!';

export const GSTIN_REQUIRED_MSG = 'Please enter GSTIN number';
export const GSTIN_MIN_DIGITS_MSG = 'GSTIN must be of 15 characters!';
export const GSTIN_INVALID_MSG = 'Invalid GSTIN!';

export const MAX_FILE_SIZE = 'Max Size of file should be 1MB!';

export const fieldLayout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 12 },
};

export const kycFlagDetails = [
    {
        flag: 'incomplete',
        title: 'KYC incomplete',
        backgroundColor: '#ffc700',
        color: 'black',
    },
    {
        flag: 'submitted',
        title: 'KYC submitted',
        backgroundColor: '#ffefb6',
        color: 'black',
    },
    {
        flag: 'complete',
        title: 'KYC complete',
        backgroundColor: '#f2f2f2',
        color: '#12805C',
        icon: true,
    },
];

export const documentLabelMapping = [
    {
        key: 'GSTIN',
        label: 'GSTIN',
        labelClassName: 'required-form-field',
        name: 'gstin',
        uploadFormName: 'gstin_doc',
        upload: true,
    },
    {
        key: 'PAN',
        label: 'PAN Number',
        name: 'pan',
        upload: true,
        uploadFormName: 'pan_card',
        required: false,
    },
    {
        key: 'AADHAAR',
        label: 'Aadhaar card Number',
        name: 'uidai',
        upload: true,
        uploadFormName: 'aadhaar_card',
        required: true,
    },
    {
        key: 'FPO',
        label: 'Society/ FPO Number',
        name: 'fpo',
        required: false,
    },
    {
        key: 'RENT AGREEMENT',
        label: 'RENT AGREEMENT',
        name: 'rentalAgreement',
        upload: true,
        uploadFormName: 'rental_agreement',
        required: true,
    },
];

export const inputFieldValue = (name: string, userDetails: any) => {
    switch (name) {
        case 'gstin':
            return userDetails?.gstin;
        case 'pan':
            return userDetails?.pan;
        case 'uidai':
            return userDetails?.uidai;
        case 'fpo':
            return userDetails?.fpo;
        case 'rentalAgreement':
            return userDetails?.rentalAgreement;
        default:
            return '';
    }
};

export const bankFieldValue = (name: string, bankInfo: any) => {
    switch (name) {
        case 'account_holder_name':
            return bankInfo?.account_holder_name;
        case 'account_no':
            return bankInfo?.account_no;
        case 'ifsc_code':
            return bankInfo?.ifsc_code;
        case 'upi_id':
            return bankInfo?.upi_id;
    }
};

export const uploadFiledValue = (name: any, userDetails: any) => {
    switch (name) {
        case 'gstin_doc':
            return userDetails.gstin_doc;
        case 'pan_card':
            return userDetails.pan_card;
        case 'aadhaar_card':
            return userDetails.aadhaar_card;
        case 'rental_agreement':
            return userDetails.rental_agreement;
    }
};

export const bankDocumentsList = [
    {
        name: 'account_holder_name',
        label: 'Account Holder Name',
        upload: false,
    },
    {
        name: 'account_no',
        label: 'Account Number',
        upload: false,
    },
    {
        name: 'ifsc_code',
        label: 'IFSC Code',
        upload: false,
    },
    {
        name: 'bank_doc',
        label: 'Bank Document',
        upload: true,
    },
    // {
    //     name: 'upi_id',
    //     label: 'UPI ID',
    //     upload: false,
    // },
];

export const workingHours = [
    {
        name: '9am_to_9pm',
        label: '9am to 9pm',
    },
    {
        name: '9am_to_5pm',
        label: '9am to 5pm',
    },
    {
        name: 'holiday',
        label: 'Holiday',
    },
    {
        name: 'all_day',
        label: 'All Day',
    },
];

export const requiredDocumentList = [
    {
        userType: 'Seller',
        subType: 'Farmer',
        title: 'Kisan Card/ RTC Number',
    },
    {
        userType: 'Seller',
        subType: 'Farmer',
        title: 'Kisan Card/ RTC Photo',
    },
    {
        userType: 'Seller',
        subType: 'Farmer',
        title: 'Aadhar Card Number',
    },
    {
        userType: 'Seller',
        subType: 'Farmer',
        title: 'Aadhar Card Photo',
    },
    {
        userType: 'Seller',
        subType: 'Farmer',
        title: 'Bank Account Details',
    },
    {
        userType: 'Seller',
        subType: 'Farmer',
        title: 'Bank statement/ Passbook/ Cheque',
    },
    {
        userType: 'Seller',
        subType: 'Institution',
        title: 'GSTIN number',
    },
    {
        userType: 'Seller',
        subType: 'Institution',
        title: 'PAN Card number',
    },
    {
        userType: 'Seller',
        subType: 'Institution',
        title: 'PAN Card photo',
    },
    {
        userType: 'Seller',
        subType: 'Institution',
        title: 'Society/ FPO number',
    },
    {
        userType: 'Seller',
        subType: 'Institution',
        title: 'Bank Account Details',
    },
    {
        userType: 'Seller',
        subType: 'Institution',
        title: 'Bank statement/ Passbook/ Cheque',
    },
    {
        userType: 'Buyer',
        subType: 'Individual',
        title: 'Aadhar Card Number',
    },
    {
        userType: 'Buyer',
        subType: 'Individual',
        title: 'Aadhar Card Photo',
    },
    {
        userType: 'Buyer',
        subType: 'Individual',
        title: 'PAN Card Number',
    },
    {
        userType: 'Buyer',
        subType: 'Individual',
        title: 'PAN Card Photo',
    },
    {
        userType: 'Buyer',
        subType: 'Institution',
        title: 'GSTIN Number',
    },
    {
        userType: 'Buyer',
        subType: 'Institution',
        title: 'PAN Card Number',
    },
    {
        userType: 'Buyer',
        subType: 'Institution',
        title: 'PAN Card Photo',
    },
];

export const initialFormValues = {
    account_holder_name: '',
    account_no: '',
    ifsc_code: '',
    pan: '',
    uidai: '',
    email: '',
    gstin: '',
};

export const KycNewDocList = [
    {
        userType: 'Seller',
        subType: 'Farmer',
        title: 'Kisan Card Number',
    },
    {
        userType: 'Seller',
        subType: 'Farmer',
        title: 'RTC Number',
    },
    {
        userType: 'Seller',
        subType: 'Farmer',
        title: 'Kisan Card Photo',
    },
    {
        userType: 'Seller',
        subType: 'Farmer',
        title: 'RTC Photo',
    },
    {
        userType: 'Seller',
        subType: 'Farmer',
        title: 'uidai',
        urd_status: false,
    },
    {
        userType: 'Seller',
        subType: 'Farmer',
        title: 'aadhaar_card',
        urd_status: false,
    },
    {
        userType: 'Seller',
        subType: 'Farmer',
        title: 'Bank Account Details',
        urd_status: false,
    },
    {
        userType: 'Seller',
        subType: 'Institution',
        title: 'gstin',
        urd_status: 'registered',
    },
    {
        userType: 'Seller',
        subType: 'Institution',
        title: 'gstin_doc',
        urd_status: 'registered',
    },
    {
        userType: 'Seller',
        subType: 'Institution',
        title: 'pan',
        urd_status: 'unregistered',
    },
    {
        userType: 'Seller',
        subType: 'Institution',
        title: 'pan_card',
        urd_status: 'unregistered',
    },
    {
        userType: 'Seller',
        subType: 'Institution',
        title: 'Bank Account Details',
        urd_status: 'unregistered',
    },
    {
        userType: 'Seller',
        subType: 'Institution',
        title: 'Bank Account Details',
        urd_status: 'registered',
    },
    {
        userType: 'Seller',
        subType: 'Institution',
        urd_status: 'unregistered',
        title: 'rental_agreement',
    },
    {
        userType: 'Seller',
        subType: 'Institution',
        urd_status: 'registered',
        title: 'rental_agreement',
    },
    {
        userType: 'Buyer',
        subType: 'Individual',
        title: 'uidai',
        urd_status: false,
    },
    {
        userType: 'Buyer',
        subType: 'Individual',
        title: 'aadhaar_card',
        urd_status: false,
    },
    {
        userType: 'Buyer',
        subType: 'Individual',
        title: 'pan',
    },
    {
        userType: 'Buyer',
        subType: 'Individual',
        title: 'pan_card',
    },
    {
        userType: 'Buyer',
        subType: 'Institution',
        title: 'gstin',
        urd_status: 'registered',
    },
    {
        userType: 'Buyer',
        subType: 'Institution',
        title: 'gstin_doc',
        urd_status: 'registered',
    },
    {
        userType: 'Buyer',
        subType: 'Institution',
        title: 'pan',
        urd_status: 'unregistered',
    },
    {
        userType: 'Buyer',
        subType: 'Institution',
        title: 'pan_card',
        urd_status: 'unregistered',
    },
    {
        userType: 'Buyer',
        subType: 'Institution',
        title: 'rental_agreement',
        urd_status: 'unregistered',
    },
    {
        userType: 'Buyer',
        subType: 'Institution',
        urd_status: 'registered',
        title: 'rental_agreement',
    },
];

export const fpoSList = [
    {
        userType: 'Buyer',
        subType: 'Institution',
        title: 'Society/ FPO number',
        urd_status: 'unregistered',
    },
    {
        userType: 'Buyer',
        subType: 'Institution',
        title: 'Society/ FPO number',
        urd_status: 'registered',
    },
    {
        userType: 'Seller',
        subType: 'Institution',
        title: 'Society/ FPO number',
        urd_status: 'registered',
    },
    {
        userType: 'Seller',
        subType: 'Institution',
        title: 'Society/ FPO number',
        urd_status: 'unregistered',
    },
    {
        userType: 'Seller',
        subType: 'Institution',
        title: 'pan',
        urd_status: 'unregistered',
    },
    {
        userType: 'Seller',
        subType: 'Institution',
        title: 'pan_card',
        urd_status: 'unregistered',
    },
    {
        userType: 'Buyer',
        subType: 'Institution',
        title: 'pan',
        urd_status: 'unregistered',
    },
    {
        userType: 'Buyer',
        subType: 'Institution',
        title: 'pan_card',
        urd_status: 'unregistered',
    },
    {
        userType: 'Buyer',
        subType: 'Institution',
        title: 'gstin',
        urd_status: 'registered',
    },
    {
        userType: 'Buyer',
        subType: 'Institution',
        title: 'gstin_doc',
        urd_status: 'registered',
    },
    {
        userType: 'Seller',
        subType: 'Institution',
        title: 'gstin',
        urd_status: 'registered',
    },
    {
        userType: 'Seller',
        subType: 'Institution',
        title: 'gstin_doc',
        urd_status: 'registered',
    },
    {
        userType: 'Buyer',
        subType: 'Institution',
        title: 'Bank Account Details',
        urd_status: 'registered',
    },
    {
        userType: 'Seller',
        subType: 'Institution',
        title: 'Bank Account Details',
        urd_status: 'registered',
    },
    {
        userType: 'Buyer',
        subType: 'Institution',
        title: 'Bank Account Details',
        urd_status: 'unregistered',
    },
    {
        userType: 'Seller',
        subType: 'Institution',
        title: 'Bank Account Details',
        urd_status: 'unregistered',
    },
];

export const checkEmpty = (label: any, loginState: any) => {
    switch (label) {
        case 'uidai':
            if (isEmpty(loginState.uidai)) return <li>Aadhaar card number</li>;
            else return null;
        case 'pan':
            if (isEmpty(loginState.pan)) return <li>PAN card number</li>;
            else return null;
        case 'aadhaar_card':
            if (isEmpty(loginState.aadhaar_card)) return <li>Aadhaar card Photo</li>;
            else return null;
        case 'pan_card':
            if (isEmpty(loginState.pan_card)) return <li>Pan card Photo</li>;
            else return null;
        case 'Bank Account Details':
            if (isEmpty(loginState.bank_doc)) return <li>Bank Account Details</li>;
            else return null;
        case 'gstin':
            if (isEmpty(loginState.gstin)) return <li>GSTIN Number</li>;
            else return null;
        case 'gstin_doc':
            if (isEmpty(loginState.gstin_doc)) return <li>GSTIN Document</li>;
            else return null;
        case 'Society/ FPO number':
            if (isEmpty(loginState.fpo)) return <li>FPO Number</li>;
            else return null;
        case 'rental_agreement':
            if (isEmpty(loginState.rental_agreement)) return <li>Rental Agreement Document</li>;
            else return null;
        default:
            return null;
    }
};
