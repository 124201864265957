import React from 'react';
import './landingPage.scss';
import { useTranslation } from 'react-i18next';
import Group from '../static/assets/group.png';
import VINAY from '../static/assets/vinay.jpeg';
import { LeftOutlined } from '@ant-design/icons';
import NAVEEV from '../static/assets/naveen.jpeg';
import { Typography, Button, Row, Col, Carousel } from 'antd';
import APOORVA from '../static/assets/apoorva.jpeg';
import SUDHESH from '../static/assets/spoorthi.jpeg';
import SPOORTHI from '../static/assets/spoorthi.jpeg';
import Ganesh from '../static/assets/rect_ganesh.png';
import OrangeLine from '../static/assets/orange_line.png';
import SatishChandra from '../static/assets/image-min.png';

const { Paragraph, Title } = Typography;

const Team = (props: { history: History }) => {
    const { t } = useTranslation('common');

    return (
        <React.Fragment>
            <div className="team_back_button">
                <Button
                    size="small"
                    icon={
                        <span className="gradient-icon">
                            <LeftOutlined />
                        </span>
                    }
                    href="/"
                >
                    <span className="gradient-text">Back</span>
                </Button>
            </div>
            <Row id="team">
                <Row className="team_title">
                    <div className="orange-box">
                        <img src={Group} />
                    </div>
                    <Title className="teamTitle">
                        <span className="first_word">Our</span> Team
                    </Title>
                    <img src={OrangeLine}></img>
                </Row>

                <Row className="team_sec">
                    <Row justify="space-around" className="team_row">
                        <Col xs={24} sm={24} md={6} xl={6} xxl={6}>
                            <img src={Ganesh}></img>
                        </Col>
                        <Col xs={24} sm={24} md={24} xl={14} xxl={14} className="teamPara">
                            <Title className="teamName" level={5}>
                                {t('our_team.advisors.name.3')}
                            </Title>

                            <p>{t('our_team.advisors.designation.3')}</p>

                            <Paragraph className="para">
                                {t('our_team.advisors.description.3')}
                            </Paragraph>
                        </Col>
                    </Row>

                    <Row justify="space-around" className="team_row">
                        <Col xs={24} sm={24} md={6} xl={6} xxl={6}>
                            <img src={SatishChandra}></img>
                        </Col>
                        <Col className="teamPara" xs={24} sm={24} md={24} xl={14} xxl={14}>
                            <Title className="teamName" level={5}>
                                {t('our_team.advisors.name.1')}
                            </Title>

                            <p>{t('our_team.advisors.designation.1')}</p>

                            <Paragraph className="para">
                                {t('our_team.advisors.description.1')}
                            </Paragraph>
                        </Col>
                    </Row>

                    <Row className="teamCarouselUnit">
                        <Carousel>
                            <div>
                                <div className="teamContainer">
                                    <img src={APOORVA}></img>
                                    <Title className="teamName" level={5}>
                                        {t('our_team.backend.name.1')}
                                    </Title>
                                    <p>{t('our_team.backend.designation.1')}</p>
                                </div>
                            </div>
                            <div>
                                <div className="teamContainer">
                                    <img src={SPOORTHI}></img>
                                    <Title className="teamName" level={5}>
                                        {t('our_team.testing.name.1')}
                                    </Title>
                                    <p>{t('our_team.testing.designation.1')}</p>
                                </div>
                            </div>
                            <div>
                                <div className="teamContainer">
                                    <img src={SUDHESH}></img>
                                    <Title className="teamName" level={5}>
                                        {t('our_team.frontend.name.1')}
                                    </Title>
                                    <p>{t('our_team.frontend.designation.1')}</p>
                                </div>
                            </div>
                            <div>
                                <div className="teamContainer">
                                    <img src={VINAY}></img>
                                    <Title className="teamName" level={5}>
                                        {t('our_team.sales.name.1')}
                                    </Title>
                                    <p>{t('our_team.sales.designation.1')}</p>
                                </div>
                            </div>
                            <div>
                                <div className="teamContainer">
                                    <img src={NAVEEV}></img>
                                    <Title className="teamName" level={5}>
                                        {t('our_team.sales.name.2')}
                                    </Title>
                                    <p>{t('our_team.sales.designation.2')}</p>
                                </div>
                            </div>
                        </Carousel>
                        <p className="teamDescription">
                            Agriculture Technology and Finance Over three decades of experience as a
                            Valuator, Finance researcher, Mentor in Agri Tech.As an advisor he is
                            mentoring us on Agri Strategy, Roadmap and finance
                        </p>
                    </Row>
                    {/* <Row justify='space-around' className="team_row">
                        <Col xs={24} sm={24} md={6} xl={6} xxl={6}>
                            <img src={APOORVA}></img>
                        </Col>
                        <Col xs={24} sm={24} md={24} xl={14} xxl={14} className='teamPara'>
                            <Title className="teamName" level={5}>
                                {t('our_team.backend.name.1')}
                            </Title>

                            <p>{t('our_team.backend.designation.1')}</p>

                            <Paragraph className="para">
                                {t('our_team.backend.description.1')}
                            </Paragraph>
                        </Col>
                    </Row>
                    <Row justify='space-around' className="team_row">
                        <Col xs={24} sm={24} md={6} xl={6} xxl={6}>
                            <img src={SPOORTHI}></img>
                        </Col>
                        <Col xs={24} sm={24} md={24} xl={14} xxl={14} className='teamPara'>
                            <Title className="teamName" level={5}>
                                {t('our_team.testing.name.1')}
                            </Title>

                            <p>{t('our_team.testing.designation.1')}</p>

                            <Paragraph className="para">
                                {t('our_team.testing.description.1')}
                            </Paragraph>
                        </Col>
                    </Row>
                    <Row justify='space-around' className="team_row">
                        <Col xs={24} sm={24} md={6} xl={6} xxl={6}>
                            <img src={SUDHESH}></img>
                        </Col>
                        <Col xs={24} sm={24} md={24} xl={14} xxl={14} className='teamPara'>
                            <Title className="teamName" level={5}>
                                {t('our_team.frontend.name.1')}
                            </Title>

                            <p>{t('our_team.frontend.designation.1')}</p>

                            <Paragraph className="para">
                                {t('our_team.frontend.description.1')}
                            </Paragraph>
                        </Col>
                    </Row>
                    <Row justify='space-around' className="team_row">
                        <Col xs={24} sm={24} md={6} xl={6} xxl={6}>
                            <img src={VINAY}></img>
                        </Col>
                        <Col xs={24} sm={24} md={24} xl={14} xxl={14} className='teamPara'>
                            <Title className="teamName" level={5}>
                                {t('our_team.sales.name.1')}
                            </Title>

                            <p>{t('our_team.sales.designation.1')}</p>

                            <Paragraph className="para">
                                {t('our_team.sales.description.1')}
                            </Paragraph>
                        </Col>
                    </Row>

                    <Row justify='space-around' className="team_row">
                        <Col xs={24} sm={24} md={6} xl={6} xxl={6}>
                            <img src={NAVEEV}></img>
                        </Col>
                        <Col xs={24} sm={24} md={24} xl={14} xxl={14} className='teamPara'>
                            <Title className="teamName" level={5}>
                                {t('our_team.sales.name.2')}
                            </Title>

                            <p>{t('our_team.sales.designation.2')}</p>

                            <Paragraph className="para">
                                {t('our_team.sales.description.2')}
                            </Paragraph>
                        </Col>
                    </Row> */}
                </Row>
            </Row>
        </React.Fragment>
    );
};

export default Team;
