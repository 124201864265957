import React from 'react';
import { useInView } from 'react-intersection-observer';
import { useSpring, animated } from 'react-spring';

const AnimatedHomeOne = ({children}:any) => {
    const [inViewRef, inView] = useInView({
        triggerOnce: true
    });

    const animationProps = useSpring({
        from: { opacity: 0, transform: 'translateX(-100px)' },
        to: { opacity: inView ? 1 : 0, transform: inView ? 'translateX(0)' : 'translateX(-100px)' },
        config: { duration: 2000 },
    });

    return (
        <animated.div ref={inViewRef} style={animationProps}>
            {children}
        </animated.div>
    );
};

export default AnimatedHomeOne;