import React from 'react';
import { Carousel, Col, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import StapleCrops from '../static/assets/rect_staple.png';
import Pulses from '../static/assets/rect_pulses.png';
import CashCrops from '../static/assets/rect_cashcrop.png';
import Spices from '../static/assets/rect_spices.png';
import OilSeeds from '../static/assets/rect_oilseeds.png';
import AnimatedComponent from './animationContents/animatedComponents';
import AnimatedKeyComponents from './animationContents/animatedKeyComponents';
const { Paragraph, Title } = Typography;

const Commodities = () => {
    const { t } = useTranslation('common');
    return (
        <div id="commodities">
            <div className="commodities-wrapper">
                <AnimatedComponent>
                    <Title className="commoditiesTitle">{t('commodities_page.title')}</Title>
                </AnimatedComponent>
                <Paragraph className="commoditiesParagraph">
                    {t('commodities_page.paragraph')}
                </Paragraph>
                <div className="CropImages_sec">
                    <div className="CropImagesWeb">
                        <AnimatedKeyComponents>
                            <div className="crops">
                                <img src={Spices} alt="Spices" />
                                <p>{t('commodities_page.commodities_name.4')}</p>
                            </div>
                        </AnimatedKeyComponents>
                        <AnimatedKeyComponents>
                            <div className="crops">
                                <img src={Pulses} alt="Pulses" />
                                <p>{t('commodities_page.commodities_name.2')}</p>
                            </div>
                        </AnimatedKeyComponents>
                        <AnimatedKeyComponents>
                            <div className="crops">
                                <img src={CashCrops} alt="CashCrops" />
                                <p>{t('commodities_page.commodities_name.3')}</p>
                            </div>
                        </AnimatedKeyComponents>
                        <AnimatedKeyComponents>
                            <div className="crops">
                                <img src={OilSeeds} alt="OilSeeds" />
                                <p>{t('commodities_page.commodities_name.5')}</p>
                            </div>
                        </AnimatedKeyComponents>
                        <AnimatedKeyComponents>
                            <div className="crops">
                                <img src={StapleCrops} alt="StapleCrops" />
                                <p>{t('commodities_page.commodities_name.1')}</p>
                            </div>
                        </AnimatedKeyComponents>
                    </div>
                    <div className="carouselContainer">
                        <Carousel autoplay dots={false} fade>
                            <div className="carousel-item">
                                <img src={StapleCrops} alt="StapleCrops" />
                                <p>{t('commodities_page.commodities_name.1')}</p>
                            </div>

                            <div className="carousel-item">
                                <img src={Pulses} alt="Pulses" />
                                <p>{t('commodities_page.commodities_name.2')}</p>
                            </div>

                            <div className="carousel-item">
                                <img src={CashCrops} alt="CashCrops" />
                                <p>{t('commodities_page.commodities_name.3')}</p>
                            </div>

                            <div className="carousel-item">
                                <img src={Spices} alt="Spices" />
                                <p>{t('commodities_page.commodities_name.4')}</p>
                            </div>

                            <div className="carousel-item">
                                <img src={OilSeeds} alt="OilSeeds" />
                                <p>{t('commodities_page.commodities_name.5')}</p>
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Commodities;
