import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Typography, Row, Col, Timeline, Button, Form, Radio } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import { isEmpty, isUndefined } from 'lodash';

import { getStatus } from '../actions';

import { RootState } from '../../store/rootReducer';
import { TransactionStatus } from '../../buyer-seller-commons/types';
import { isDeliveryRecievedPayload } from '../../store/buyerReducer/actions';

const { Text } = Typography;

const TransactionDetailsModal = (props: any) => {
    const { pk, tab, producestatus } = props;
    const dispatch = useDispatch();
    const loginState = useSelector((state: RootState) => state.loginUser);
    const userState = useSelector((state: RootState) =>
        loginState.is_buyer ? state.buyer : state.seller,
    );
    const { status, eventTemplate, currentStatusDetails } = userState;
    const [count, setCount] = useState(status.length - 1);
    const [currentStatus, setCurrentStatus] = useState([]);
    const [latestStatus, setLatestStatus]: any = useState('');
    const [deliveryValue, setDeliveryValue]: any = useState('no');
    var id = pk;
    id = id.substring(12);

    console.log(pk, 'pk');

    const data = {
        transactionId: id,
        user: loginState.is_buyer ? 'buyer' : 'seller',
    };

    useEffect(() => {
        dispatch(getStatus(data));
        for (let i = 0; i < currentStatusDetails.length; i++) {
            if (currentStatusDetails[i].pk === pk) {
                setLatestStatus(currentStatusDetails[i].event_description);
            }
        }
    }, []);

    useEffect(() => {
        for (let i = 0; i < status.length; i++) {
            if (status[i].key === id) {
                setCurrentStatus(status[i].details);
                setCount(status[i].details.length);
            }
        }
    }, [status]);

    const showDetails = (details: any, isDownload: boolean) => {
        console.log(details, 'details');
        let showDetails = [];

        for (const key in details) {
            showDetails.push(details[key]);
        }
        return showDetails.map((list: any) => {
            return isDownload ? (
                <React.Fragment>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {!isUndefined(list.pdf_link) ? (
                            <Button
                                type="link"
                                href={`${list.pdf_link}`}
                                target="_blank"
                                download={''}
                            >
                                Download
                            </Button>
                        ) : null}
                    </div>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <Text>
                        {list.event_name} {list.event_time && `(${list.event_time})`}
                    </Text>
                    <br />
                </React.Fragment>
            );
        });
    };

    const showDeliveryConfirm = () => {
        const submitForm = (value: any) => {
            const deliveryPayload = {
                userType: loginState.is_buyer ? 'buyer' : 'seller',
                transactionId: id,
                isgoodsreceived: value.deliveryRecived,
            };
            if (value.deliveryRecived === 'no') {
                return window.alert('Please select yes,This feature is in progress');
            }
            if (value.deliveryRecived === 'yes') {
                setDeliveryValue(value.deliveryRecived);
                console.log(deliveryPayload, 'deliveryPayload');
                dispatch(isDeliveryRecievedPayload(deliveryPayload));
            }
        };

        const submitFormSeller = (value:any)=>{
            const dispatchPayload = {
                userType: loginState.is_buyer ? 'buyer' : 'seller',
                transactionId: id,
                isgoodsdispatched: value.goodsDispatched,
            }
            if (value.goodsDispatched === 'no') {
                return window.alert('Please select yes,This feature is in progress');
            }
            if (value.goodsDispatched === 'yes') {
                setDeliveryValue(value.goodsDispatched);
                console.log(dispatchPayload, 'deliveryPayload');
                dispatch(isDeliveryRecievedPayload(dispatchPayload));
            }
        }

        return (
            <React.Fragment>
                <Form onFinish={loginState.is_buyer?submitForm:submitFormSeller} layout="inline">
                    <Form.Item
                        label= {loginState.is_buyer?"Delivery Recived":'Goods dispatched'}
                        name={loginState.is_buyer?"deliveryRecived":'goodsDispatched'}
                        rules={[{ required: true, message: 'This value is manditory' }]}
                    >
                        <Radio.Group>
                            <Radio value="yes" onChange={(e: any) => e.target.value}>
                                {' '}
                                Yes{' '}
                            </Radio>
                            <Radio value="no" onChange={(e: any) => e.target.value}>
                                {' '}
                                No{' '}
                            </Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item>
                        <Button className="deliverySubmitBtn" htmlType="submit">
                            confirm
                        </Button>
                    </Form.Item>
                </Form>
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <Timeline
                mode="left"
                style={{ float: 'left' }}
                className="transaction-timeline"
                pending={tab === TransactionStatus.on_going && count !== eventTemplate.length}
            >
                {currentStatus.map((completedStatus: any) => {
                    console.log(completedStatus,"completedStatus")
                    const isPending =
                        completedStatus.event_description === latestStatus &&
                        tab !== TransactionStatus.completed;
                    return (
                        <Timeline.Item
                            label={isPending ? '-' : completedStatus.event_timestamp}
                            dot={
                                isPending ? null : (
                                    <CheckCircleFilled style={{ color: '#12805C' }} />
                                )
                            }
                            color={'#F5A31A'}
                            className={isPending ? 'is-pending' : 'is-complete'}
                        >
                            <Row>
                                <Col span={6}>
                                    <Row>
                                        <Text>{completedStatus.event_description}</Text>
                                    </Row>
                                    <Row>
                                        <Text>
                                            {!isEmpty(completedStatus.event_details) &&
                                                showDetails(completedStatus.event_details, false)}
                                        </Text>
                                    </Row>
                                </Col>
                                <Col span={2} className={isPending ? 'display-none' : ''}>
                                    <Text style={{ color: '#12805C' }}>Complete</Text>
                                </Col>
                                <Col span={8}>
                                    {showDetails(completedStatus.event_details, true)}
                                </Col>
                            </Row>
                        </Timeline.Item>
                    );
                })}
                {console.log(deliveryValue,'deliveryValue')}
                {console.log(latestStatus,'latestStatus')}
                {(deliveryValue === 'no' && producestatus==='ongoing') && (
                    <Row
                        style={{
                            position: 'absolute',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                        }}
                    >
                        {(latestStatus === 'Is goods recieved?' || latestStatus === 'Is goods dispatched?')? showDeliveryConfirm() : null}
                    </Row>
                )}
            </Timeline>
        </React.Fragment>
    );
};

export default TransactionDetailsModal;
