import React from 'react';
import { Typography, Select } from 'antd';

import { MatchRequirementModel } from '../../buyer-seller-commons/types';
import { maskData, parseIDfromHash } from '../../app-components/utils';

const { Title } = Typography;
const { Option } = Select;

type RejectConfrimationProps = {
    matchRecord: MatchRequirementModel
};

const RejectConfrimation = (props: RejectConfrimationProps) => {
    const { matchRecord } = props;
    const { produce, destinyId } = matchRecord;

    return (
        <>
            <Title level={5}>Buyer {destinyId} : {produce}</Title>
            <p>Select your reason for rejection</p>
            <Select defaultValue='reasons'>
                <Option value='Out of stock'>Out of stock</Option>
                <Option value='Quantity not matched'>Quantity not matched</Option>
                <Option value='Price not matched'>Price not matched</Option>
            </Select>
        </>
    );
};

export default RejectConfrimation;
