export const routesMap = {
    home: '/',
    register_buyer: '/buyer',
    register_seller: '/seller',
    register_entity: '/register/:id',
    seller_ui: '/seller',
    buyer_ui: '/buyer',
    terms: '/terms&conditions',
    purchase_agreement: '/agreement',
    profile: '/profile',
    loginUser: '/login-user',
    contactUs:'/contactUs',
    legal:'/legal',
    updates:'/updates',
};


export const headerBreadcrumb = {
    produce: "-ui-app",
    crops: "-ui-crops",
    matches: "-ui-matches",
    transaction: "-ui-transactions",
    feedback: "-ui-feedback",
}

export const contactUs = {
    phoneNumber: "+91-7406165550",
    emailId: "contactus@samparkbindhu.in"
}

export const token_grant = 'vikas-bandhu-token'