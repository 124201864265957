import React, { useState } from 'react';
import { Modal } from 'antd';
import { useSelector } from 'react-redux';
import { History } from 'history';

import './registration.scss';
import RequestSubmittedPopup from './requestSubmittedPopup';
import DocumentsUploadComponents from './documentsUpload';

import Header from '../../header';

import { RootState } from '../../store/rootReducer';

const Registration = (props: { history: History; showKycOne: boolean; setShowKycOne: any }) => {
    const { history, setShowKycOne } = props;
    const [complusaryFormValues, setComplusaryFormValues] = useState({});
    const [showSubmitMsgPopup, toggleShowSubmitMsgPopup] = useState(false);
    const [showMandatory, setShowMandatory] = useState(true);
    const registrationState = useSelector((state: RootState) => state.registration);
    const { configs, entityType, formData } = registrationState;
    const { type, category } = formData;

    window.onbeforeunload = function () {
        return 'The data will be lost on reload of page. Are you sure?';
    };

    return (
        <div className="registrationBaseModal">
            <RequestSubmittedPopup history={history} showSubmitMsgPopup={showSubmitMsgPopup} />
            <Header />
            <Modal
                className="registration-modal-body1"
                title={`${registrationState.entityType} Profile Verification`}
                open={props.showKycOne}
                footer={null}
                closable={false}
            >
                {showMandatory ? (
                    <DocumentsUploadComponents
                        setShowKycOne={setShowKycOne}
                        showKycOne={props.showKycOne}
                        history={history}
                        userType={entityType}
                        subType={type}
                        documents_list={configs}
                        setShowMandatory={setShowMandatory}
                        category={category}
                        setComplusaryFormValues={setComplusaryFormValues}
                    />
                ) : null}
            </Modal>
        </div>
    );
};

export default Registration;
