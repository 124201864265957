import { Col, Form, Input, Modal, Row } from 'antd';
import React from 'react';
import PrimaryBtn from '../app-components/primaryBtn';
import CancelBtn from '../app-components/cancelBtn';
import { validatePhoneNumber } from '../login-ui/register/utils';
import { fieldLayout } from './constants';
import { useTranslation } from 'react-i18next';
import './profile.scss';

export const ChangingPhoneModal = (props: any) => {
    const { loginState, changePnumber, sendUserPhNo, setConfirmation, setSaveFlag } = props;
    const [form] = Form.useForm();
    const { t } = useTranslation('common');

    return (
        <React.Fragment>
            <Modal open={changePnumber} footer={null}>
                <Form
                    className="changingPhoneNumberForm"
                    style={{ display: 'flex', flexDirection: 'column' }}
                    form={form}
                    labelAlign="left"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 18 }}
                    onFinish={sendUserPhNo}
                >
                    <Form.Item
                        {...fieldLayout}
                        name="admin_phone_no"
                        label="User existing phone no"
                        className="margin-zero"
                    >
                        {loginState.phone_no}
                    </Form.Item>
                    <br></br>
                    <Form.Item
                        {...fieldLayout}
                        name="user_phone_no"
                        label="User new phone no"
                        className="margin-zero"
                        rules={[
                            {
                                required: true,
                                validator: (rule, value) => validatePhoneNumber(rule, value),
                            },
                        ]}
                    >
                        <Input className="custom-input" />
                    </Form.Item>
                    <br></br>
                    <div
                        style={{
                            alignSelf: 'center',
                            width: '70%',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-around',
                        }}
                        className='changingPhoneNumber'
                    >
                        <PrimaryBtn
                            htmlType="submit"
                            style={{ width: '25%' }}
                            content={t('Register.Verify')}
                        />
                        <CancelBtn
                            onClick={() => {
                                setConfirmation(true);
                                setSaveFlag(false);
                            }}
                            style={{ width: '25%' }}
                        />
                    </div>
                </Form>
            </Modal>
        </React.Fragment>
    );
};
