import React,{useRef} from 'react'
import {useIdleTimer} from 'react-idle-timer';
import { LOGOUT_URL } from '../src/store/api';

const IdleTimerContainer = ()=> {
    const onIdle =()=>{
        console.log("5 seconds over");
        window.location.href = LOGOUT_URL;
        localStorage.clear(); 
    }
    const idleTimerRef = useRef(null)
    
    
    const idleTimer= useIdleTimer({
      crossTab: true,
      ref: idleTimerRef,
      onIdle: onIdle,
      timeout: 1000*60*60*2 //1000ms x 60seconds x 60minutes = hours
      // timeout: 1000*60*1
      })
  return (
    <>
    <div idletimer={idleTimer}></div>
    </>
  )
}

export default IdleTimerContainer;