import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import englishTranslation from './static/translations/en.json';
import kannadaTranslation from './static/translations/kn.json';
import tamilTranslation from './static/translations/ta.json';

export const initi18n = () => {
    return i18n
        .use(initReactI18next)
        .use(LanguageDetector)
        .init({
            resources: {
                en: {
                    common: englishTranslation,
                },
                kn: {
                    common: kannadaTranslation,
                },
                ta:{
                    common:tamilTranslation
                },
            },
            fallbackLng: 'en',
            defaultNS: 'common',
            detection: {
                order: ['cookie', 'htmlTag', 'localStorage', 'path', 'subdomain'],
                caches: ['cookie'],
            },
        });
};
