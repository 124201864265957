import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Avatar,
    Badge,
    Breadcrumb,
    Button,
    Card,
    Dropdown,
    Menu,
    notification,
    Popconfirm,
    Space,
    Tooltip,
    Typography,
} from 'antd';
import { BellFilled, ContactsFilled, LogoutOutlined } from '@ant-design/icons';
import MenuItem from 'antd/lib/menu/MenuItem';
import { isEmpty } from 'lodash';
import { History } from 'history';
// @ts-ignore you just tell typescript to ignore the next line for now until type definitions become available via npm install @types/react-notifications (still not available as of writing).
import Notifications from 'react-notifications-menu';
import { contactUs, headerBreadcrumb, routesMap } from '../constants';

import '../header/header.scss';
import { LOGOUT_URL } from '../store/api';
import { RootState } from '../store/rootReducer';
import DefaultBtn from '../app-components/defaultBtn';
import {
    getConfigurations,
    getUserCompleteDetails,
    getUserDetails,
    getUserFiles,
    getUserNotification,
} from '../store/loginReducer/actions';
import { useTranslation } from 'react-i18next';
import Notification from './notification';

const { Text, Title } = Typography;
const { profile, terms } = routesMap;

const UserHeader = (props: { history: History }) => {
    console.log('im inside userheader')
    const { history } = props;
    const loginState: any = useSelector((state: RootState) => state.loginUser);
    console.log(loginState,'loginState in userHeader')
    const dispatch = useDispatch();
    // const fieldOfficer = 'Mahesh Kumar';
    // const fieldOfficerNumber = '9876543210';
    // const [notificationNumber, setNotificationNumber] = useState(2);
    const [userType, setUserType] = useState('');
    const [showProfile, setProfile] = useState(false);
    const [showTerms, setShowTerms] = useState(false);
    const [imageSrc, setImageSrc] = useState();
    const [isPDF, setPDF] = useState(false);
    const [breadCrumbs, setBreadCrumbs] = useState({
        produce: '',
        matches: '',
        transaction: '',
        feedback: '',
        crops: '',
    });
    const { t } = useTranslation('common');

    const clientName =
        loginState.name.length <= 10 ? loginState.name : loginState.name.substr(0, 8) + '...';

    useEffect(() => {
        loginState.is_buyer ? setUserType('/buyer#buyer') : setUserType('/seller#seller');
        headerCrumbNames();
    }, [userType]);

    useEffect(() => {
        dispatch(getConfigurations());
    }, []);

    useEffect(() => {
        dispatch(getUserNotification(loginState.phone_no));
    }, [loginState?.phone_no]);

    useEffect(() => {
        console.log('im here in getcomplete');
        // dispatch(getUserDetails());
        dispatch(getUserCompleteDetails());
    }, []);

    useEffect(() => {
        if (!isEmpty(loginState?.profile_picture)) {
            console.log("login state's profile picture", loginState.profile_picture);
            dispatch(getUserFiles(loginState?.profile_picture?.doc_key, setImageSrc, setPDF));
        }
    }, [loginState]);

    const headerCrumbNames = () => {
        const produceName = userType + headerBreadcrumb.produce;
        const matchesName = userType + headerBreadcrumb.matches;
        const transactionName = userType + headerBreadcrumb.transaction;
        const feedbackName = userType + headerBreadcrumb.feedback;
        const cropsName = userType + headerBreadcrumb.crops;
        setBreadCrumbs({
            produce: produceName,
            matches: matchesName,
            transaction: transactionName,
            feedback: feedbackName,
            crops: cropsName,
        });
    };

    const showContactInfo = () => {
        return (
            <Menu>
                <MenuItem key="fieldOfficerInfo">
                    <Card>
                        <Title level={4}>Field Officer Info:</Title>
                        <Space direction="vertical">
                            {/* <Text>Name: {fieldOfficer}</Text>
                            <Text>Phone No: {fieldOfficerNumber}</Text> */}
                            <Text>Field Officer will be assigned shortly.</Text>
                        </Space>
                    </Card>
                </MenuItem>
                <MenuItem key="contactUs">
                    <Card>
                        <Title level={4}>Contact Us:</Title>
                        <Text>{contactUs.emailId}</Text>
                    </Card>
                </MenuItem>
            </Menu>
        );
    };

    return (
        <div className="display-flex-row align-center profile-margin">
            <Breadcrumb separator=" " className="custom-breadcrumb">
                <Breadcrumb.Item href={breadCrumbs.crops}>
                    {t('UserHeader.breadcrumb.produce')}
                </Breadcrumb.Item>
                <Breadcrumb.Item href={breadCrumbs.matches}>
                    {t('UserHeader.breadcrumb.matches')}
                </Breadcrumb.Item>
                <Breadcrumb.Item href={breadCrumbs.transaction}>
                    {t('UserHeader.breadcrumb.transaction')}
                </Breadcrumb.Item>
                <Breadcrumb.Item href={breadCrumbs.feedback}>
                    {t('UserHeader.breadcrumb.feedback')}
                </Breadcrumb.Item>
            </Breadcrumb>
            <Button
                className="termsAndConditions"
                style={{ color: 'black', fontSize: '1rem' }}
                size="large"
                type="link"
                // style:onFocus={{}}
                onClick={() => setShowTerms(true)}
            >
                Terms And Conditions
            </Button>
            {showTerms && history.location.pathname !== terms ? history.push(terms) : null}
            {/* <Badge
                // count={notificationNumber}
                className='custom-badge'
            >
                <Tooltip title='Notifications'>
                    <DefaultBtn
                        shape='circle'
                        size='large'
                        icon={<BellFilled style={{ fontSize: 'large', paddingLeft: '0.3em' }} />}
                        onClick={openNotification}
                    />
                </Tooltip>
            </Badge> */}
            {/* <Notifications
                data={loginState.userNotification}
                header={{
                    title: 'Notifications',
                    option: { text: 'View All', onClick: () => console.log('Clicked') },
                }}
                cardOption={(data:any) => console.log(data)}
                markAsRead={(data:any) => console.log('im clicked')}
                classNamePrefix="okrjoy"
            /> */}
            <Notification notificationData={loginState.userNotification} loginState={loginState} />
            <Dropdown.Button
                overlay={showContactInfo}
                icon={<ContactsFilled style={{ fontSize: 'large' }} />}
                size="large"
                className="custom-dropdown-button contact-info"
            />
            <Popconfirm
                title={t('Logout.title')}
                okText={t('Logout.y')}
                onConfirm={() => {
                    window.location.href = LOGOUT_URL;
                    localStorage.clear();
                }}
                cancelText={t('Logout.n')}
            >
                <Tooltip title={t('Logout.logout')}>
                    <Button
                        icon={<LogoutOutlined style={{ fontSize: 'large' }} />}
                        size="large"
                        shape="circle"
                    />
                </Tooltip>
            </Popconfirm>
            <div className="profile-name-id ">
                <Tooltip title={loginState.name}>
                    <Title level={4} className="margin-unset" style={{ paddingTop: '1rem' }}>
                        {clientName}
                    </Title>
                </Tooltip>
                <p className="margin-unset">
                    {loginState.is_buyer
                        ? `${t('Register.content2')}`
                        : `${t('Register.content1')}`}{' '}
                    Id: {loginState.destinyId}
                </p>
            </div>
            <Tooltip title="Profile">
                <Button
                    className="profile-avatar-button"
                    shape="circle"
                    size="large"
                    onClick={() => setProfile(true)}
                    icon={
                        isEmpty(imageSrc) ? (
                            <Avatar size="large" className="profile-avatar">
                                {loginState.name.charAt(0)}
                            </Avatar>
                        ) : (
                            <Avatar size="large" className="profile-avatar" src={imageSrc} />
                        )
                    }
                ></Button>
            </Tooltip>
            {showProfile && history.location.pathname !== profile ? history.push(profile) : null}
        </div>
    );
};

export default UserHeader;
