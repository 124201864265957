import { FullfillmentFlags } from './types';

export const initialEmptyCropDetail = {
    fulfillment_flag: FullfillmentFlags.single_fulfillment,
    produce: '',
    seller_id: '',
    quantity: 0,
    seller_location: '',
    buyer_id: '',
    buyer_crop_id: '',
    seller_quantity: 0,
    seller_remaining_quantity: 0,
    buyer_remaining_quantity: 0,
    seller_final_price: 0,
    seller_price: 0,
    buyer_actual_quantity: 0,
    buyer_location: '',
    seller_crop_id: '',
    matched_quantity: 0
};

const CropImages = {
    Copra: require('../static/assets/copra.jpg'),
    GroundNutSeed: require('../static/assets/groundnut.jpg'),
    BengalGramDal: require('../static/assets/channadal.jpg'),
    GreenGram: require('../static/assets/greengram.jpg'),
    BlackGramDal: require('../static/assets/uraddal.jpg'),
    TurDal: require('../static/assets/turdaal.jpg'),
    Tur: require('../static/assets/tur.jpg'),
    Pepper: require('../static/assets/pepper.jpg'),
    Turmeric: require('../static/assets/turmericpowder.jpg'),
    Maize: require('../static/assets/maize1.jpg'),
};

/** Show crop images based on category name
 * 
 * @param { string } category - Category name
 */
export const showCropImage = (produce: string) => {
    switch (produce) {
        case 'Copra': return CropImages.Copra;
        case 'Ground Nut Seed': return CropImages.GroundNutSeed;
        case 'Bengal Gram Dal': return CropImages.BengalGramDal;
        case 'Green Gram': return CropImages.GreenGram;
        case 'Black Gram Dal': return CropImages.BlackGramDal;
        case 'Tur Dal': return CropImages.TurDal;
        case 'Tur': return CropImages.Tur;
        case 'Pepper': return CropImages.Pepper;
        case 'Turmeric': return CropImages.Turmeric;
        case 'Maize': return CropImages.Maize;
    }
};

/** Check if the crop details entered is unique or not
 * 
 * @param { any } cropDetails - Crop details
 * @param { any } otpError - Otp error
 * @returns boolean 
 */
export const checkIfUnique = (cropDetails: any, otpError: any) => {
    const sellerIdCheck = cropDetails.seller_id === otpError.sellerId;
    const sellerCropIdCheck = cropDetails.seller_crop_id === otpError.sellerCropId;
    const buyerIdCheck = cropDetails.buyer_id === otpError.buyerId;
    const buyerCropIdCheck = cropDetails.buyer_crop_id === otpError.buyerCropId;

    return sellerIdCheck && sellerCropIdCheck && buyerIdCheck && buyerCropIdCheck;
};
