import React, { useState } from 'react';

import { Button, Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { englishStyling, isEnglish, kannadaStyling } from '../static/translations/constants';
import AnimatedComponent from './animationContents/animatedComponents';

const { Text, Title } = Typography;

const Aim = () => {
    const [vkText, setVkText]: any = useState('Existing');
    const { t } = useTranslation('common');
    const customStyles = isEnglish(t('language')) ? englishStyling : kannadaStyling;

    const ChangeVkText: any = () => {
        if (vkText === 'Existing' || vkText === '') {
            return (
                <React.Fragment>
                    <p className="pointsTxt">
                        No Dedicated Post Harvest (Agri Output) Marketplaces available today
                    </p>
                    <p className="pointsTxt">
                        The existing Market places uses Bid or Manual connect method between Farmers
                        and Buyers.
                    </p>
                    <p className="pointsTxt">{`They have a poor connection to transaction ratio(<5%).None of them have tried to solve this core issue.`}</p>
                </React.Fragment>
            );
        }
        if (vkText === 'Sellers') {
            return (
                <React.Fragment>
                    <p className="pointsTxt">
                        85-90% of farming is done by small and marginal farmers.
                    </p>
                    <p className="pointsTxt">
                        They don’t have reliable source to guide them to make right decision while
                        selling the produce.
                    </p>
                    <p className="pointsTxt">
                        Farmers/FPOs don’t get a fair price for their produce.
                    </p>
                </React.Fragment>
            );
        }
        if (vkText === 'Buyers') {
            return (
                <React.Fragment>
                    <p className="pointsTxt">Many middle men in the supply chain.</p>
                    <p className="pointsTxt">Lack of quality options to find right seller.</p>
                    <p className="pointsTxt">Unstructured agri ecosystem.</p>
                </React.Fragment>
            );
        }
    };

    return (
        <div id="aim">
            <Title className="whyVbTitle">
                <span>Why</span> VikasBandhu?
            </Title>
            <Text className="whyVbText">
                At present, sellers and buyers face many issues while trading
            </Text>
            <AnimatedComponent>
                <Row className="whyVbContainer">
                    <Col xs={24} sm={24} md={24} lg={12} className="firstContent">
                        <Button
                            className={vkText === 'Existing' ? `eButton ${vkText}` : 'eButton'}
                            onClick={() => {
                                setVkText('Existing');
                            }}
                        >
                            <Text className={vkText === 'Existing' ? vkText : null}>
                                Existing Market Places
                            </Text>
                        </Button>
                        <Button
                            className={vkText === 'Sellers' ? `sellerBtn ${vkText}` : 'sellerBtn'}
                            onClick={() => {
                                setVkText('Sellers');
                            }}
                        >
                            <Text
                                className={
                                    vkText === 'Sellers' ? `sellerBtnTxt ${vkText}` : 'sellerBtnTxt'
                                }
                            >
                                Sellers <p>(FPOs, Mills, Storage units, Agents)</p>
                            </Text>
                        </Button>
                        <Button
                            className={vkText === 'Buyers' ? `buyerBtn ${vkText}` : 'buyerBtn'}
                            onClick={() => {
                                setVkText('Buyers');
                            }}
                        >
                            <Text
                                className={
                                    vkText === 'Buyers' ? `buyerBtnTxt ${vkText}` : 'buyerBtnTxt'
                                }
                            >
                                Buyers{' '}
                                <p>
                                    (Wholesalers, Food Processors,
                                    <br />
                                    Food Parks, Institutions)
                                </p>
                            </Text>
                        </Button>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} className="secondContent">
                        {vkText === 'Existing' ? (
                            <Title></Title>
                        ) : vkText === 'Sellers' ? (
                            <Title></Title>
                        ) : (
                            <Title></Title>
                        )}
                        <Row>
                            <ChangeVkText />
                        </Row>
                    </Col>
                </Row>
            </AnimatedComponent>
        </div>
    );
};

export default Aim;
