import { isEmpty } from "lodash";
import { RuleObject } from 'antd/lib/form';

export const validateQuantity = (rule: RuleObject, value: string, unit: string) => {
    const regExp = /^\d+(\.\d{1,2})?$/;
    const numericValue = parseFloat(value);

    if (!value) {
        return Promise.reject('Please input the Quantity!');
    } else if (!regExp.test(value)) {
        return Promise.reject('Please enter a valid quantity!');
    } else if (numericValue < 1) {
        return Promise.reject('The minimum value for quantity must be 1!');
    } else if (numericValue > 2000) {
        return Promise.reject('The maximum value for quantity must be 2000!');
    } else if (unit === 'kg' && numericValue < 1) {
        return Promise.reject('The minimum value for quantity in kg must be 1!');
    } else if (unit === 'qtl' && numericValue < 0.25) {
        return Promise.reject('The minimum value for quantity in qtl must be 0.25!');
    } else if (unit === 'ton' && numericValue < 0.001) {
        return Promise.reject('The minimum value for quantity in ton must be 0.001!');
    } else {
        return Promise.resolve();
    }
};