import React, { useState } from 'react';
import { Form, Input, Select } from 'antd';
import { validateQuantity } from './produce/utils';
import { isUndefined } from 'lodash';

const { Option } = Select;

const QuantityComponent = (props: any) => {
    const { record, dataIndex } = props;
    const [unit, setUnit] = useState('Kg.');
    const [quantity, setQuantity] = useState('');

    const handleQuantityChange = (value: any) => {
        setQuantity(value);
    };

    const handleUnitChange = (value: any) => {
        setUnit(value);
    };

    const suffixSelector = (
        <Form.Item name="unit" noStyle>
            <Select style={{ width: 70 }} onChange={handleUnitChange} defaultValue="Kg.">
                <Option value="Kg.">Kg</Option>
                <Option value="Quintal">Qtl</Option>
                <Option value="Tonne">Ton</Option>
            </Select>
        </Form.Item>
    );

    return (
        <>
            <Form.Item
                label="Quantity"
                name={dataIndex ? dataIndex : 'quantity'}
                initialValue={record?.quantity ? record?.quantity : ''}
                rules={[
                    {
                        required: true,
                        validator: (rules, value) =>
                            validateQuantity(rules, quantity, isUndefined(unit) ? 'Kg.' : unit),
                    },
                ]}
            >
                <Input
                    style={{ width: 'calc(100% - 80px)' }}
                    className="custom-input"
                    placeholder="Quantity"
                    addonAfter={suffixSelector}
                    onChange={(e) => handleQuantityChange(e.target.value)}
                />
            </Form.Item>
        </>
    );
};

export default QuantityComponent;
