
import React, { useEffect, useState } from 'react';
import { Collapse, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { CaretRightOutlined, CaretDownOutlined } from '@ant-design/icons';

import mvp from '../static/assets/VbThree.png';
import foodExb from '../static/assets/VbTwo.jpg';
import academia from '../static/assets/VbFive.jpg';
import patents from '../static/assets/patentsFiled.png';
import buyerSellerCon from '../static/assets/buyerSellerCon.jpeg';
import Fpos from '../static/assets/FPO (1).jpg';
import IIITBang from '../static/assets/IIITBangalore.jpeg';
import { useDispatch, useSelector } from 'react-redux';
import { getUserUpdates } from '../store/registrationReducer/actions';
import { RootState } from '../store/rootReducer';
import { Image, Spin } from 'antd';
import moment from 'moment';

const { Title, Text } = Typography;

const Updates = () => {
    const updatesData: any = useSelector((state: RootState) => state.registration.updates);
    const [showOldUpdates, setShowOldUpdates] = useState(false);
    const [showFullText, setShowFullText] = useState(false);
    const { t } = useTranslation('common');
    const { Panel } = Collapse;
    const dispatch = useDispatch();

    useEffect(() => {
        let myVar = true;
        if (myVar) {
            dispatch(getUserUpdates());
        }
        return () => {
            myVar = false;
        };
    }, []);

    const toggleShowFullText = () => {
        setShowFullText(!showFullText);
    };

    const customPanel = (item: any) => {
        return (
            <div>
                {item?.value.length <= 259 ? (
                    <p>{item?.value}</p>
                ) : (
                    <div>
                        {showFullText ? (
                            <p>{item?.value}</p>
                        ) : (
                            <p>
                                {item?.value.substring(0, 259)}
                                ... <button onClick={toggleShowFullText}>Read more</button>
                            </p>
                        )}
                    </div>
                )}
            </div>
        );
    };

    const groupedData = updatesData?.textData?.reduce((groups: any, textItem: any) => {
        const key = Object.keys(textItem)[0]; // Extract the key
        const value = textItem[key]; // Extract the value
        const { createdAt } = textItem;
        const urlItem = updatesData?.urlData?.find((urlItem: any) => urlItem[key]); // Find corresponding URL
        if (!groups[key]) {
            groups[key] = [];
        }

        const imageUrl = urlItem?.[key];
        const createdAtDate = moment(createdAt, 'DD-MM-YY');
        const currentDate = moment();

        const itemData: any = { value, imageUrl, createdAt, key };

        // Calculate the difference in months
        const monthDiff = currentDate.diff(createdAtDate, 'months');

        if (monthDiff >= 3) {
            // If createdAt is more than 3 months older, add to oldData
            if (!groups.oldData) {
                groups.oldData = [];
            }
            groups?.oldData?.push(itemData);
            delete groups[`${key}`];
        } else {
            groups[key].push(itemData);
        }
        return groups;
    }, {});

    const oldData = groupedData?.oldData;

    return (
        <>
            {groupedData ? (
                <div id="updates">
                    <div className="updateWrapper">
                        <Title className="updates-title">{t('updates_page.title')}</Title>
                        <Collapse
                            accordion
                            bordered={false}
                            // defaultActiveKey={['3']}
                            expandIcon={({ isActive }) => (
                                <CaretRightOutlined rotate={isActive ? 90 : 0} />
                            )}
                            className="site-collapse-custom-collapse"
                            onChange={() => {
                                setShowFullText(false);
                            }}
                        >
                            {Object.keys(groupedData).map((key: any) => (
                                <>
                                    {key === 'oldData' ? null : (
                                        <Panel
                                            className="site-collapse-custom-panel"
                                            header={key}
                                            key={key}
                                        >
                                            {groupedData[key].map((item: any, index: number) => (
                                                <>
                                                    <div key={index} className="card">
                                                        <Image src={item?.imageUrl} alt={key} />
                                                        <>{customPanel(item)}</>
                                                    </div>
                                                </>
                                            ))}
                                        </Panel>
                                    )}
                                </>
                            ))}
                        </Collapse>
                        {groupedData?.oldData ? (
                            <div
                                className="oldUpdates"
                                onClick={() => {
                                    showOldUpdates
                                        ? setShowOldUpdates(false)
                                        : setShowOldUpdates(true);
                                }}
                            >
                                {showOldUpdates ? <CaretDownOutlined /> : <CaretRightOutlined />}
                                <Text>{t('updates_page.text8')}</Text>
                            </div>
                        ) : null}

                        {showOldUpdates ? (
                            <Collapse
                                accordion
                                bordered={false}
                                // defaultActiveKey={['3']}
                                expandIcon={({ isActive }) => (
                                    <CaretRightOutlined rotate={isActive ? 90 : 0} />
                                )}
                                className="site-collapse-custom-collapse"
                                onChange={() => {
                                    setShowFullText(false);
                                }}
                            >
                                {oldData.map((item: any, index: number) => (
                                    <>
                                        <Panel
                                            className="site-collapse-custom-panel"
                                            header={item.key}
                                            key={index}
                                        >
                                            <div key={index} className="card">
                                                <Image src={item?.imageUrl} alt={item.value} />
                                                <>{customPanel(item)}</>
                                            </div>
                                        </Panel>
                                    </>
                                ))}
                            </Collapse>
                        ) : null}
                    </div>
                </div>
            ) : (
                <Spin
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                    }}
                    size="large"
                />
            )}
        </>
    );
};

export default Updates;
