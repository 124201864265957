import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Home from './App';
import RegisterBuyer from './login-ui/registration';
import SellerUI from './seller-ui/';
import BuyerUI from './buyer-ui';
import ValidateUserAuthentication from './validateUserAuthentication';
import ValidateUserRedirection from './validateUserRedirection';
import { routesMap } from './constants';
import GuardedRoute from './app-components/GuardedRoute';
import { Terms } from './terms-and-conditions';
import { PurchaseAgreement } from './purchase-agreement';
import Profile from './profile';
import { useDispatch} from 'react-redux';
import { setAmplifyResponse, setSuccessInLogin } from './store/loginReducer/actions';
import ContactUs from './landing-page/contactUs';
import Team from './landing-page/team';
import Legal from './landing-page/legal';
import Updates from './landing-page/updates';

const { buyer_ui, home, profile, purchase_agreement, register_entity, seller_ui, terms,contactUs,legal,updates } = routesMap;

const HomePageRoutes = () => {
    const dispatch = useDispatch();
        
    window.onload=()=>{
        if(localStorage.length){
            dispatch(setSuccessInLogin());
            dispatch(setAmplifyResponse(true));
            }
    }
    if(window.location.href == 'http://localhost:3000/' && localStorage.length){
        console.log(window.location.href)
        window.location.href = '/seller'
    }
    
    return (
        <Switch>
            <Route exact path={home} component={Home} />
            <Route path={register_entity} component={RegisterBuyer} />
            {/* <Route path={seller_ui} component={SellerUI} /> */}
            {/* <Route path={buyer_ui} component={BuyerUI} /> */}
            <Route path={'/team'} component={Team}/>
            <Route path={'/login-user'} component={ValidateUserAuthentication} />
            <Route path={'/redirected-user'} component={ValidateUserRedirection} />
            <Route exact path={terms} component={Terms} />
            <Route exact path={purchase_agreement} component={PurchaseAgreement} />
            <Route exact path={contactUs} component={ContactUs} />
            <Route exact path={legal} component={Legal} />
            <Route exact path={updates} component={Updates} />
            {/* Protected route guard */}
            <GuardedRoute exact Component={SellerUI} path={seller_ui} auth={false} />
            <GuardedRoute exact Component={BuyerUI} path={buyer_ui} auth={false} />
            <GuardedRoute exact Component={Profile} path={profile} auth={false} />
        </Switch>
    );
};

export default HomePageRoutes;
