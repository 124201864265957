// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: 'AIzaSyDunhbGZctUYEPLDLCPbsrlvG4sIEj3uD8',
    authDomain: 'notification-services-7f1c7.firebaseapp.com',
    projectId: 'notification-services-7f1c7',
    storageBucket: 'notification-services-7f1c7.appspot.com',
    messagingSenderId: '959235208591',
    appId: '1:959235208591:web:d833ccad406f016a8fc7fa',
    measurementId: 'G-LW6C7J7GX8',
};

// Initialize Firebase
// if ("Notification" in window){
// console.log('Requesting permission...');
Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
        // console.log('Notification permission granted.');
        const app = initializeApp(firebaseConfig);
        const messaging = getMessaging(app);
        getToken(messaging, {
            vapidKey:
                'BJtq2vy-u4giUYWd9pXpmV6rSHW6318iBvFs8yLPBYiPM2y0x7_PQSlpAe5SdAy0cTCY16cVrtWWP6lOATJ08_I',
        }).then((res:any) => {
            if (res) {
                (window as any).deviceId = res;
            }
        });
    }else{
        console.log('Notification permission not granted.');
    }
});
// }
