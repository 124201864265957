import React from 'react';
import { Button, Typography, Image } from 'antd';
import { showCropImage } from '../constants';
import ViewCropImages from '../viewCropImages';
import { isEmpty } from 'lodash';
import ConnectMatches from './connectMatches';
import confirmationPopup from '../confirmationPopup';
import { openAdditionalInfo } from '../openAdditionalInfo';
import PrimaryBtn from '../../app-components/primaryBtn';

const { Title, Text } = Typography;

const SingleFullDataTable = (props: any) => {
    const { singleBestData, isChild, componentCallBacks, is_buyer } = props;
    const { showCropDetailsModal, populateCropDetails, rejectMatch, showConnectModal } =
        componentCallBacks;

    if (!isEmpty(singleBestData)) {
        const produce: any = singleBestData.produce;
        console.log(produce);
        var [masterCategory = '', produceCateogry = '', cropType = '', grade = '']: any =
            produce.split('-');
        var imageSrc = showCropImage(produceCateogry);
        const fullFillment: any = singleBestData.buyer_remaining_quantity;
        var FulfilmentComp: any = () =>
            fullFillment <= 0 ? (
                <Text className="full-match">FULL</Text>
            ) : (
                <Text className="partial-match">PARTIAL</Text>
            );
    }

    return (
        <React.Fragment>
            {!isEmpty(singleBestData) ? (
                <table className="bestMatchesFullTable">
                    <tbody>
                        <tr>
                            <td className="sellrId">{singleBestData?.destinyId}</td>
                            <td className="producePhotoCat">
                                <React.Fragment>
                                    <div>
                                        {!isChild && (
                                            <Image
                                                preview={false}
                                                src={imageSrc}
                                                className="table-crop-image"
                                            />
                                        )}
                                        <div className="gradeCategory">
                                            <Title className="more-matches-text" level={5}>
                                                {produceCateogry.trim()} - {cropType.trim()}
                                            </Title>
                                            <Text className="more-matches-text">
                                                {grade.trim()}
                                            </Text>
                                        </div>
                                    </div>
                                    <div className="additionalContent">
                                        {!isEmpty(singleBestData.additional_info) && (
                                            <Button
                                                type="link"
                                                disabled={isEmpty(singleBestData.additional_info)}
                                                onClick={() =>
                                                    openAdditionalInfo(
                                                        singleBestData.additional_info,
                                                    )
                                                }
                                            >
                                                Additional Info
                                            </Button>
                                        )}
                                        {!isEmpty(singleBestData.cropImageList) && (
                                            <ViewCropImages
                                                list={singleBestData.cropImageList}
                                                disablePhotos={false}
                                            />
                                        )}
                                    </div>
                                </React.Fragment>
                            </td>
                            <td className="sellrId sellerDisplayFlex">
                                <div>
                                    {singleBestData?.matched_quantity}{' '}
                                    {singleBestData.buyer_unit !== 'Tonne'
                                        ? singleBestData.buyer_unit
                                        : 'Ton'}
                                </div>
                                <div>
                                    <FulfilmentComp />
                                </div>
                            </td>
                            <td className="sellrId">{singleBestData?.buyer_total_price}</td>
                            <td className="sellrId">{singleBestData?.seller_location}</td>
                            <td className="sellrId">
                                <Button
                                    className="view-details-button"
                                    type="link"
                                    onClick={() => {
                                        populateCropDetails(singleBestData);
                                        showCropDetailsModal(true);
                                    }}
                                >
                                    View Details
                                </Button>
                            </td>
                            <td className="sellrId">
                                <PrimaryBtn
                                    className="vikas-btn-radius connect-button"
                                    onClick={() => {
                                        showConnectModal(true);
                                        populateCropDetails(singleBestData);
                                    }}
                                    content={'Connect'}
                                />
                            </td>
                            <td className="sellrId">
                                {isChild ? null : (
                                    <Button
                                        className="reject-button"
                                        type="link"
                                        danger
                                        onClick={() =>
                                            confirmationPopup('reject', rejectMatch, singleBestData)
                                        }
                                    >
                                        Reject
                                    </Button>
                                )}
                            </td>
                        </tr>
                    </tbody>
                </table>
            ) : null}
        </React.Fragment>
    );
};

export default SingleFullDataTable;
