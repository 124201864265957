import React from 'react';
import { useInView } from 'react-intersection-observer';
import { useSpring, animated } from 'react-spring';

const AnimatedComponent = ({ children }: any) => {
    const [inViewRef, inView] = useInView({
        triggerOnce: true,
    });

    const animationProps = useSpring({
        from: { opacity: 0, transform: 'translateY(100px)' },
        to: { opacity: inView ? 1 : 0, transform: inView ? 'translateY(0)' : 'translateY(100px)' },
        config: { duration: 2000 },
    });

    return (
        <animated.div ref={inViewRef} style={animationProps}>
            {children}
        </animated.div>
    );
};

export default AnimatedComponent;
