import { Badge, Button, Tooltip, Typography } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import DefaultBtn from '../app-components/defaultBtn';
import { BellFilled } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
    clearAllNotifiFun,
    markAsReadFun,
} from '../store/loginReducer/actions';
import { RootState } from '../store/rootReducer';

const { Text, Title } = Typography;

const Notification = (props: any) => {
    const [showNotification, setShowNotification] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [markAsRead, SetMarkAsRead] = useState(false);
    const [storeSelectedItemId, setStoreSelectedItemId] = useState(
        localStorage.getItem('storeSelectedItemId'),
    );
    const loginState: any = useSelector((state: RootState) => state.loginUser);
    const notificationRef: any = useRef();
    const scrollRef = useRef(null);
    const dispatch = useDispatch();
    const notificationData: Array<any> = loginState?.userNotification;
    
    useEffect(() => {
        function handleClickOutside(event: any) {
            if (notificationRef.current && !notificationRef.current.contains(event.target)) {
                setShowNotification(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [notificationRef]);

    useEffect(() => {
        if (storeSelectedItemId) {
            localStorage.setItem('storeSelectedItemId', storeSelectedItemId);
        } else {
            localStorage.removeItem('storeSelectedItemId');
        }
    }, [storeSelectedItemId]);

    function handleMarkAsRead(data: any) {
        const title = data.title
        if(title.includes('Crop')){
            const idName = loginState.is_buyer ? 'buyer-ui-crops' : 'seller-ui-crops';
            const element = document.getElementById(idName);
            if (element) {
                element.scrollIntoView({behavior:"smooth",});
              }
        }
        if(title.includes('Match')){
            const idName = loginState.is_buyer ? 'buyer-ui-matches' : 'seller-ui-matches';
            const element = document.getElementById(idName);
            if (element) {
                element.scrollIntoView({behavior:"smooth",});
              }
        }
        if(title.includes('Transaction')){
            const idName = loginState.is_buyer ? 'buyer-ui-transactions' : 'seller-ui-transactions';
            const element = document.getElementById(idName);
            if (element) {
                element.scrollIntoView({behavior:"smooth",});
              }
        }
        const dataItem = {
            phone_no: loginState.phone_no,
            id:data.id,
        };
        dispatch(markAsReadFun(dataItem, dispatch));
    }

    const handleCLearAll = () => {
        const data = {
            phone_no: loginState.phone_no,
        };
        dispatch(clearAllNotifiFun(data, dispatch));
    };

    return (
        <div>
            <Badge count={notificationData.length} className="custom-badge">
                <Tooltip title="Notifications">
                    <Button
                        shape="circle"
                        size="large"
                        icon={<BellFilled style={{ fontSize: 'large'}} />}
                        onClick={() => setShowNotification(!showNotification)}
                    />
                </Tooltip>
            </Badge>
            {showNotification && notificationData.length != 0 ? (
                <div ref={notificationRef} className="notificationBody">
                    <section>
                        <Button onClick={handleCLearAll}>Clear All</Button>
                    </section>
                    {notificationData
                        .slice()
                        .reverse()
                        .map((data: any) => (
                            <section
                                key={data.id}
                                className={
                                    data.markedAsRead
                                        ? `notificationItem backGroundWhite`
                                        : `notificationItem backGroundBlue`
                                }
                                onClick={() => {
                                    setSelectedItemId(data.id);
                                    setStoreSelectedItemId(data.id);
                                    handleMarkAsRead(data);
                                }}
                            >
                                <Title level={5}>{data.title}</Title>
                                <article>{data.message}</article>
                                <Text style ={{color:'#62CDFF'}}>{data.timestamp}</Text>
                            </section>
                        ))}
                </div>
            ) : null}
        </div>
    );
};

export default Notification;
